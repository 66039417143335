import { GetCustomerBarChartResponse } from '@/api';
import { UseQueryResult } from '@tanstack/react-query';
import { groupBy, map } from 'lodash';

export const getCustomerBarChartResources = <TResponse extends GetCustomerBarChartResponse>(
	customerSourceQueryResults: UseQueryResult<TResponse, Error>[]
) => {
	const isLoading = customerSourceQueryResults.some((o) => o.isFetching === true);

	const dataGroup = groupBy(
		customerSourceQueryResults.flatMap((o) => o.data),
		(o) => o?.keyName
	);

	const dataChart: {
		labelName: string;
		value: number;
	}[] = [];

	map(dataGroup, (items, name) => {
		const total = items.reduce((s, item) => {
			return (s += item?.total ?? 0);
		}, 0);

		dataChart.push({
			labelName: name,
			value: total,
		});
	});

	const domainTo = dataChart.reduce((s, item) => (s += item.value), 0);

	return {
		domainTo,
		isLoading,
		dataChart,
	};
};
