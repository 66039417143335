import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { ServiceCategoryByBookingDetailTable } from "./table-detail/ServiceCategoryByBookingDetailTable";
import { getReportBookingByServiceCategory, GetReportBookingByServiceCategoryItem } from "@/api";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
import { usePaginationBookingServiceCategory } from "../hooks";
import { useCallback, useMemo } from "react";
import { formatDateTime } from "@/utils";

interface ServiceCategoryByBookingTableProps {
    readonly dateRange: [Dayjs, Dayjs]
}

export function ServiceCategoryByBookingTable({dateRange} : ServiceCategoryByBookingTableProps) {

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);

    const [pagination, setPagination] = usePaginationBookingServiceCategory();

    const { data: bookingByServiceCategoryResponse, isFetching } = useHttpQuery(getReportBookingByServiceCategory, {
        query: {
            startDate: formatDateTime(dateRange[0]),
            endDate: formatDateTime(dateRange[1]),
            salonId: salonActive?.id,
            search: pagination.serviceCategorySearch,
            page: pagination.serviceCategoryPage,
            pageSize: pagination.serviceCategoryPageSize,
            orderBy: pagination.serviceCategoryOrderBy,
            orderDirection: pagination.serviceCategoryOrderDirection,
            useCountTotal: true
        }
    })

    const showDetail = useCallback((title: string, serviceCategoryId: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <ServiceCategoryByBookingDetailTable serviceCategoryId={serviceCategoryId} dateRange={dateRange} />
        });
    }, [dateRange, globalModal, setGlobalModal]);

    const columns: TableColumnsType<GetReportBookingByServiceCategoryItem> = useMemo(() => [
        {
            key: 'serviceCategoryName',
            title: 'Tên danh mục',
            dataIndex: 'serviceCategoryName',
        },
        {
            key: 'totalQuantity',
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            render: (value: number) => value?.toLocaleString('en-US'),
            width: 250,
            sorter: true,
        },
        {
            key: 'totalRevenueBooking',
            title: 'Doanh thu',
            dataIndex: 'totalRevenueBooking',
            width: 150,
            render: (value: number) => value?.toLocaleString('en-US'),
            sorter: true,
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <TagViewDetailColumn showDetail={()=> showDetail(record.serviceCategoryName ?? '', record.serviceCategoryId ?? 0)}/>
                );
            },
        },
    ], [showDetail]);

    return (
        <TableCustom
            scroll={{ x: 800 }}
            titleTableTransCode="Theo danh mục dịch vụ"
            loading={isFetching}
            onSearch={(value) => {
                setPagination({
                    ...pagination,
                    serviceCategorySearch: value
                });
            }}
            onPageChange={(nextPage) => {
                setPagination({
                    ...pagination,
                    serviceCategoryPage: nextPage.toString()
                });
            }}
            onSort={(orderDirection, orderBy) => {
                setPagination({
                    ...pagination,
                    serviceCategoryOrderDirection: orderDirection,
                    serviceCategoryOrderBy: orderBy
                });
            }}
            placeholderSearchTransCode="Tìm danh mục dịch vụ"
            showHeader={true}
            columns={columns}
            dataSource={bookingByServiceCategoryResponse?.items}
            pageInfo={bookingByServiceCategoryResponse?.meta}
        />
    )
}
