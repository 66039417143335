import { accountInfoState, salonActiveState } from "@/components/state";
import { useMemo } from "react";
import { useRecoilState } from "recoil";

export const useSalonIds = (salonBranchId?: number) => {
    const [salonActive] = useRecoilState(salonActiveState);
    const [currentAccount] = useRecoilState(accountInfoState);


	const result = useMemo(
		() => {
			if (salonActive) {
				return [{
					id: salonActive.id,
					isDivideCustomer: salonActive.isDivideCustomerByBranch === 'true' ? true : false,
					salonBranchIds: salonBranchId ? [salonBranchId] : salonActive.salonBranches?.map(s => s.id) ?? []
				}]
			}

			return (
				currentAccount?.salons?.map((salon) => ({
					id: salon.id,
					isDivideCustomer:
						salon.isDivideCustomerByBranch === 'true' ? true : false,
					salonBranchIds: salon.salonBranches?.map((s) => s.id) ?? [],
				})) ?? []
			);
			
		},
		[salonActive, salonBranchId, currentAccount]
	);

    return result
}