import { env } from '@env';
import { httpUtil } from '@/framework';
import { GetCustomerChartRequest, GetCustomerTotalChartResponse } from './Types';

export const getCustomerWalkInChart = httpUtil.createHttpRequestMeta<
    GetCustomerChartRequest,
    GetCustomerTotalChartResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/walk-in-chart',
    method: 'GET',
    authentication: 'bearer'
});
