import { Tag } from 'antd';

interface TagViewDetailColumnProps {
    readonly showDetail: () => void;
}

export const TagViewDetailColumn = (props: TagViewDetailColumnProps) => {
    return (
        <Tag 
            onClick={props.showDetail} 
            style={{ borderRadius: 2, backgroundColor: 'white', color: '#8F9BB2', cursor: 'pointer' }}
        >
            Xem chi tiết
        </Tag>
    );
};