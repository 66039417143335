
import { env } from '@env';
import { HttpRequestData, httpUtil, PaginationResponse } from '@/framework';

export interface GetReportBookingByServiceDetailRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetReportBookingByServiceDetailItem  {
    readonly salonId?: number;
    readonly salonBranchId?: number;
    readonly totalRevenueBooking?: number;
    readonly totalQuantity?: number;
}

export const getReportBookingByServiceDetail = httpUtil.createHttpRequestMeta<
    GetReportBookingByServiceDetailRequest,
    PaginationResponse<GetReportBookingByServiceDetailItem>
>({
    baseUrl: env.orgServer,
    path: '/bookings/by-service-detail',
    method: 'GET',
    authentication: 'bearer'
});
