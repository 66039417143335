import { env } from '@env';
import { httpUtil } from '@/framework';
import { GetCustomerChartRequest, GetCustomerTotalChartResponse } from './Types';

export const getCustomerReturnChart = httpUtil.createHttpRequestMeta<
	GetCustomerChartRequest,
	GetCustomerTotalChartResponse
>({
	baseUrl: env.orgServer,
	path: '/customers/return-chart',
	method: 'GET',
	authentication: 'bearer',
});
