import { Dayjs } from 'dayjs';
import { CustomerReportBarChart } from '../CustomerReportBarChart';
import { getCustomerGroupChart } from '@/api/report/customer/chart/getCustomerGroupChart';

interface ReportCustomerGroupBarChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salonBranchId?: number;
}

export const ReportCustomerGroupBarChart = (props: ReportCustomerGroupBarChartProps) => {
    const { dateRange, salonBranchId } = props;

    
    return (
        <CustomerReportBarChart
            chartTitle='Nhóm khách hàng'
            dateRange={dateRange}
            salonBranchId={salonBranchId}
            requestMeta={getCustomerGroupChart}
        />
    );
}