import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Auth } from './auth';
import { ProtectedRoute } from "../components/app/ProtectedRoute";
import { MainLayout } from '../layouts';
import Authorization from './authorization/Authorization';
import Account from './account/Account';
import Salon from './salon/Salon';
import Report from './report/Reports';
import CustomerReport from './report/customer/CustomerReport';
import { BookingReport } from './report/booking/BookingReport';
import { WarehouseReport } from './report/warehouse/WarehouseReport';
import { RevenueReport } from './report/revenue/RevenueReport';

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/auth/*' Component={Auth} />
                <Route path='/' element={<ProtectedRoute />}>
                    <Route path='/' Component={MainLayout} >
                        <Route path='/' element={<Navigate to={'/report/revenue'} />} />
                        <Route path='/account/*' Component={Account} />
                        <Route path='/report' Component={Report} >
                            <Route index element={<Navigate to="/report/revenue" replace />} />
                            <Route path='/report/revenue' Component={RevenueReport} />
                            <Route path='/report/customer' Component={CustomerReport} />
                            <Route path='/report/booking' Component={BookingReport} />
                            <Route path='/report/warehouse' Component={WarehouseReport} />
                        </Route>
                        <Route path='/salon/*' Component={Salon} />
                        <Route path='/authorization/*' Component={Authorization} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};