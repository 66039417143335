import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { Dayjs } from "dayjs";
import { useHttpQuery } from "@/hooks";
import RevenueByObjectTypeDetailTable from "./table-details/RevenueByObjectTypeDetailTable";
import { useCallback, useMemo } from "react";
import { getRevenueOfObjectTypeTable, GetRevenueOfObjectTypeTableResponse } from "@/api";
import { usePaginationObjectType } from "../hooks";
import { formatDateTime } from "@/utils";

interface RevenueByObjectTypeTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
}

export function RevenueByObjectTypeTable(props: RevenueByObjectTypeTableProps) {
    const [salonActive] = useRecoilState(salonActiveState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [pagination, setPagination] = usePaginationObjectType();

    const {data : revenueOfObjectTypeTable, isFetching} = useHttpQuery(getRevenueOfObjectTypeTable, {
        query: {
            startDate: formatDateTime(props.dateRange[0]),
            endDate: formatDateTime(props.dateRange[1]),
            objectType: props.objectType,
            salonId: salonActive?.id ?? 0,
            search: pagination.objectTypeSearch,
            page: pagination.objectTypePage,
            pageSize: pagination.objectTypePageSize,
            orderDirection: pagination.objectTypeOrderDirection,
            orderBy: pagination.objectTypeOrderBy,
            useCountTotal: true
        }
    });

    const showDetail = useCallback((title?: string, objectId?: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: (
                <RevenueByObjectTypeDetailTable
                    dateRange={props.dateRange}
                    objectType={props.objectType}
                    objectId={objectId!}
                />
            )
        });
    }, [globalModal, props.dateRange, props.objectType, setGlobalModal]);

    const objectTypeTransCode = props.objectType === 'SERVICE' ? 'dịch vụ' : 'sản phẩm';

    const columns: TableColumnsType<GetRevenueOfObjectTypeTableResponse> =  useMemo(()=> [
        {
            key: 'objectName',
            title: `Tên ${objectTypeTransCode}`,
            dataIndex: 'objectName',
        },
        {
            key: 'totalQuantity',
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            sorter: true,
            render: (value: number) => value.toLocaleString('en-US')
        },
        {
            key: 'discount',
            title: 'Giảm giá',
            dataIndex: 'discount',
            sorter: true,
            render: (discount: number) => discount.toLocaleString('en-US'),
        },
        {
            key: 'totalRevenue',
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            width: 150,
            sorter: true,
            render: (value: number) => value.toLocaleString('en-US')
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <TagViewDetailColumn showDetail={()=> showDetail(record.objectName, record.objectId)} />
                );
            },
        },
    ], [objectTypeTransCode, showDetail]);

    return (
        <TableCustom
            loading={isFetching}
            scroll={{ x: 800 }}
            placeholderSearchTransCode={`Tìm ${objectTypeTransCode}`}
            onSearch={(search) => {
                setPagination({
                    ...pagination,
                    objectTypeSearch: search,
                });
            }}
            onPageChange={(page) => {
                setPagination({
                    ...pagination,
                    objectTypePage: page.toString(),
                });
            }}
            onSort={(orderDirection, orderBy) => {
                setPagination({
                    ...pagination,
                    objectTypeOrderDirection: orderDirection,
                    objectTypeOrderBy: orderBy
                });
            }}
            pageInfo={revenueOfObjectTypeTable?.meta}
            titleTableTransCode={`Doanh thu theo từng ${objectTypeTransCode}`}
            showHeader={true}
            columns={columns}
            dataSource={revenueOfObjectTypeTable?.items}
        />
    )
}
