import { Bar } from '@ant-design/plots';
import { Card } from 'antd';
import { Block } from '@components/layouts';
import { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { useHttpQueries } from '@/hooks';
import { HttpRequestMeta } from '@/framework';
import { getCustomerBarChartResources } from '../@share/utils';
import { GetCustomerBarChartResponse } from '@/api';
import { useCustomerQueriesRequest } from '../../../@share/hooks';

interface DataChart {
	labelName: string;
	value: number;
}

interface CustomerBarChartProps<TRequest, TResponse> {
	readonly chartTitle: string;
	readonly salonBranchId?: number;
	readonly dateRange: [Dayjs, Dayjs];
	requestMeta: HttpRequestMeta<TRequest, TResponse>;
}

export const CustomerReportBarChart = <TRequest, TResponse extends GetCustomerBarChartResponse>({
	chartTitle,
	dateRange,
	salonBranchId,
	requestMeta,
}: CustomerBarChartProps<TRequest, TResponse>) => {
	const queries = useCustomerQueriesRequest({
		dateRange,
		salonBranchId,
		requestsMeta: [requestMeta],
	});

	const customerQueryResults = useHttpQueries(queries);

	const { dataChart, domainTo, isLoading } = getCustomerBarChartResources(customerQueryResults);
	const config = useMemo(
		() => ({
			data: dataChart,
			xField: 'labelName',
			yField: 'value',
			height: dataChart.length * 90,
			style: {
				maxWidth: 10,
				radius: 10,
			},
			className: 'bar-chart',
			markBackground: {
				label: {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					text: ({ originData }: any) => {
						return `${originData.value}`;
					},
					position: 'right',
					dy: -20,
					style: {
						fill: '#aaa',
						fillOpacity: 1,
						fontSize: 14,
					},
				},
				style: {
					fill: '#eee',
				},
			},
			titleFontSize: 18,
			label: {
				position: 'left',
				dy: -20,
				text: ({ labelName }: DataChart) => {
					return `${labelName}`;
				},
				style: {
					fill: '#1E1E1E',
					fontSize: 14,
				},
			},
			scale: {
				y: {
					domain: [0, domainTo],
				},
			},
			labelFontSize: 18,
			axis: {
				x: {
					tick: false,
					title: false,
					label: false,
				},
				y: {
					labelFontSize: 14,
				},
			},
			interaction: {
				elementHighlightByColor: false,
			},
		}),
		[dataChart, domainTo]
	);

	return (
		<Block width='100%'>
			<Card title={chartTitle} style={{ height: '100%' }}>
				<Bar {...config} loading={isLoading} />
			</Card>
		</Block>
	);
};
