
import { env } from '@env';
import { HttpRequestData, httpUtil, PaginationResponse } from '@/framework';

export interface GetReportBookingByStaffDetailRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetReportBookingByStaffDetailItem  {
    readonly salonId?: number;
    readonly serviceName?: string;
    readonly totalRevenueBooking?: number;
    readonly totalQuantity?: number;
}

export const getReportBookingByStaffDetail = httpUtil.createHttpRequestMeta<
    GetReportBookingByStaffDetailRequest,
    PaginationResponse<GetReportBookingByStaffDetailItem>
>({
    baseUrl: env.orgServer,
    path: '/bookings/by-staff-detail',
    method: 'GET',
    authentication: 'bearer'
});
