export const defaultDateFormat = 'YYYY-MM-DD HH:mm:ss';

import dayjs, { Dayjs } from 'dayjs';

export const formatDateTime = (date: Dayjs | string) => {
	if (typeof date === 'string') {
		date = dayjs(date);
	}

	return date.format(defaultDateFormat);
};
