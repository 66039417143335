import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { FlexBox } from "@components/layouts";
import { AppAvatar } from "@components/avatar";
import { Text } from '@components/typo';
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { Dayjs } from "dayjs";
import { useHttpQuery } from "@/hooks";
import { RevenueByStaffDetailTable } from "./table-details/RevenueByStaffDetailTable";
import { useCallback, useMemo } from "react";
import { getRevenueOfStaffTable, GetRevenueOfStaffTableResponse } from "@/api";
import { usePaginationRevenueStaff } from "../hooks";
import { formatDateTime } from "@/utils";

interface RevenueByStaffTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
}

export function RevenueByStaffTable(props: RevenueByStaffTableProps) {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);
    const [pagination, setPagination] = usePaginationRevenueStaff();

    const {data : revenueOfStaffTable, isFetching} = useHttpQuery(getRevenueOfStaffTable, {
        query: {
            startDate: formatDateTime(props.dateRange[0]),
            endDate: formatDateTime(props.dateRange[1]),
            objectType: props.objectType,
            salonId: salonActive?.id ?? 0,
            search: pagination.staffSearch,
            page: pagination.staffPage,
            pageSize: pagination.staffPageSize,
            orderDirection: pagination.staffOrderDirection,
            orderBy: pagination.staffByOrderBy,
            useCountTotal: true
        }
    });

    const showDetail = useCallback((title?: string, staffId?: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode:
                <p>
                    <span>Doanh thu sản phẩm nhân viên: </span>
                    <span style={{ color: 'grey', fontWeight: 400 }}>{title}</span>
                </p>,
            width: 850,
            content: <RevenueByStaffDetailTable dateRange={props.dateRange} objectType={props.objectType} staffId={staffId!}/>
        });
    }, [globalModal, props.dateRange, props.objectType, setGlobalModal]);

    const columns: TableColumnsType<GetRevenueOfStaffTableResponse> = useMemo(() => [
        {
            title: 'Tên nhân viên',
            dataIndex: 'staffName',
            key: 'name',
            render: (_, record) => {
                return <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                    <AppAvatar
                        size='sm'
                        url={record.avatar}
                    />
                    <Text maxWidth={350} fontWeight='bold'>{record.staffName}</Text>
                </FlexBox>;
            },
        },
        {
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            width: 300,
            sorter: true,
            render: (value: number) => value.toLocaleString('en-US'),
        },
        {
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            width: 150,
            sorter: true,
            render: (value: number) => value.toLocaleString('en-US'),
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <TagViewDetailColumn showDetail={()=> showDetail(record.staffName, record.staffId)} />
                );
            },
        },
    ], [showDetail]);

    const objectTypeTransCode = props.objectType === 'SERVICE' ? 'dịch vụ' : 'sản phẩm';
    return (
        <TableCustom
            loading={isFetching}
            scroll={{ x: 800 }}
            titleTableTransCode={`Doanh thu ${objectTypeTransCode} theo nhân viên`}
            onSearch={(value) => {
                setPagination({
                    ...pagination,
                    staffSearch: value
                });
            }}
            onPageChange={(nextPage) => {
                setPagination({
                    ...pagination,
                    staffPage: nextPage.toString()
                });
            }}
            onSort={(orderDirection, orderBy) => {
                setPagination({
                    ...pagination,
                    staffOrderDirection: orderDirection,
                    staffByOrderBy: orderBy
                });
            }}
            pageInfo={revenueOfStaffTable?.meta}
            placeholderSearchTransCode='Tìm nhân viên'
            showHeader={true}
            columns={columns}
            dataSource={revenueOfStaffTable?.items}
        />
    )
}
