
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';

export interface GetBookingTotalNewCustomerRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly salonBranchId?: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetBookingTotalNewCustomerResponse {
    readonly totalNewCustomers?: number;
    readonly salonId?: number;
    readonly salonBranchId?: number;
}

export const getBookingTotalNewCustomer = httpUtil.createHttpRequestMeta<
    GetBookingTotalNewCustomerRequest,
    GetBookingTotalNewCustomerResponse
>({
    baseUrl: env.orgServer,
    path: '/bookings/total-new-customers',
    method: 'GET',
    authentication: 'bearer'
});
