import { Card, Tag } from 'antd';
import { Dayjs } from 'dayjs';
import { Block, FlexBox } from '@components/layouts';
import { Text } from '@components/typo';
import { Line } from '@ant-design/charts';
import { useMemo } from 'react';
import { useHttpQueries } from '@/hooks';
import {
	getCustomerRevenueChartResources,
	getCustomerTotalChartResources,
	getCustomerTotalChartTooltipRender,
} from './@share/utils';
import {
	getCustomerNewChart,
	getCustomerReturnChart,
	getCustomerRevenueChart,
	getCustomerTotalOverview,
	getCustomerWalkInChart,
} from '@/api';
import { TooltipItemValue } from '@antv/g2';
import { useCustomerQueriesRequest } from '../../@share/hooks';

interface ReportCustomerMainChartProps {
	readonly dateRange: [Dayjs, Dayjs];
	readonly salonBranchId?: number;
}
export const CustomerTotalReportChart = ({
	dateRange,
	salonBranchId,
}: ReportCustomerMainChartProps) => {
	const queries = useCustomerQueriesRequest({
		dateRange,
		salonBranchId,
		requestsMeta: [getCustomerNewChart, getCustomerReturnChart, getCustomerWalkInChart],
		applyView: true
	});

	const customerRevenueQueries = useCustomerQueriesRequest({
		dateRange,
		salonBranchId,
		requestsMeta: [getCustomerRevenueChart],
		applyView: true,
	});

	const customerTotalQueries = useCustomerQueriesRequest({
		dateRange,
		salonBranchId,
		requestsMeta: [getCustomerTotalOverview],
	});

	const queryResults = useHttpQueries(queries);

	const customerTotalQueryResults = useHttpQueries(customerTotalQueries);

	const customerRevenueQueryResults = useHttpQueries(customerRevenueQueries);

	const customerTotal = useMemo(
		() =>
			customerTotalQueryResults
				.map((o) => o.data)
				.reduce((s, item) => (s += item?.value ?? 0), 0),
		[customerTotalQueryResults]
	);

	const { dataChart, isLoading } = useMemo(
		() => getCustomerTotalChartResources(queryResults, dateRange),
		[dateRange, queryResults]
	);

	const { dataRevenue } = useMemo(
		() => getCustomerRevenueChartResources(customerRevenueQueryResults, dateRange),
		[customerRevenueQueryResults, dateRange]
	);

	const config = useMemo(
		() => ({
			data: dataChart,
			xField: 'date',
			yField: 'value',
			colorField: 'type',
			scrollbar: { x: { ratio: 0.5 } },
			axis: {
				y: {
					labelFormatter: (v: unknown) =>
						`${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
				},
			},
			scale: { color: { range: ['#56C22D', '#F5222D', '#F8AC2F'] } },
			style: {
				lineWidth: 2,
			},
			legend: {
				color: {
					layout: {
						justifyContent: 'flex-end',
					},
					itemMarker: 'circle',
					itemLabelFontSize: 14,
					colPadding: 30,
				},
			},
		}),
		[dataChart]
	);

	return (
		<Block overflow='hidden' maxWidth='100%'>
			<Card>
				<FlexBox alignItems='center' gap={12}>
					<Text fontSize={18} fontWeight={600}>
						Tổng khách hàng
					</Text>
					<Tag color='blue'>
						<Text fontWeight={500} fontSize={14} color='blueviolet'>
							{customerTotal}
						</Text>
					</Tag>
				</FlexBox>
				<Line
					loading={isLoading}
					{...config}
					interaction={{
						tooltip: {
							render: (
								_: unknown,
								{
									items,
									title,
								}: {
									title: 'string';
									items: TooltipItemValue[];
								}
							) => {
								const revenue = dataRevenue.find((o) => o.date === title)?.value;

								return getCustomerTotalChartTooltipRender(
									items,
									title,
									revenue ?? 0
								);
							},
						},
					}}
				/>
			</Card>
		</Block>
	);
};
