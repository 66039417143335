import { Column } from '@ant-design/charts';
import { chartHelper } from '@/helpers';
import { useMemo } from 'react';
import { GetBookingOverviewChartResponseItem } from '@/api';
import { Dayjs } from 'dayjs';
import { ViewType } from '@/shared/enum';

interface BookingColumnChartProps {
    readonly bookingChartData: GetBookingOverviewChartResponseItem[];
    readonly dateRange: [Dayjs, Dayjs];
}
interface ColumnData {
    readonly xField: string;
    readonly yField: number;
    readonly color?: string;
    readonly record?: GetBookingOverviewChartResponseItem;
}

export default function BookingColumnChart(props: BookingColumnChartProps) {
    const { bookingChartData, dateRange } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);

    const dataChart = useMemo(() => {
        const data = bookingChartData?.map((item) => {
            return [
                {
                    xField: chartHelper.getLabelDateByViewChart(viewType,item.dateTimeGroup),
                    yField: item.totalBooking,
                    name: 'Tổng số lịch hẹn',
                    color: '#2970FF',
                    record: item,
                },
                {
                    xField: chartHelper.getLabelDateByViewChart(viewType, item.dateTimeGroup),
                    yField: item.totalBookingCancel,
                    name: 'Lịch hẹn đã hủy',
                    color: '#F04438',
                    record: item,
                },
            ]
        })

        return data?.flat() ?? [];
    },[bookingChartData, viewType])

    const configChartColumn = useMemo(() => ({
        data: dataChart,
        xField: 'xField',
        yField: 'yField',
        colorField: 'name',
        group: true,
        sizeField: 35,
        scrollbar: {  x: {
            ratio: (viewType === ViewType.Hour || viewType === ViewType.Day) ? 0.7 : 1,
            style : { trackSize: 15 }
        }},
        interaction: {
            tooltip: {
            render: (_: React.ReactNode, { title }: { title: string}) => {

                const record = dataChart?.find((item) => item.xField === title)?.record;
                const overviewOfColumn = [
                    {
                        name: 'Tổng số lịch hẹn',
                        value: record?.totalBooking,
                        color: '#0068FF',
                    },
                    {
                        name: 'Lịch hẹn đã hủy',
                        value: record!.totalBookingCancel,
                        percent: record!.totalBookingCancel / record!.totalBooking * 100,
                        color: '#F5222D',
                    },
                    {
                        name: 'Đặt lịch online',
                        value: record?.totalBookingOnline,
                        percent: record!.totalBookingOnline / record!.totalBooking * 100,
                        color: '#56C22D',
                    },
                    {
                        name: 'Đặt lịch tại salon',
                        value: record?.totalBookingSalon,
                        percent: record!.totalBookingSalon / record!.totalBooking * 100,
                        color: '#F8AC2F'
                    },
                    {
                        name: 'Doanh thu đặt lịch',
                        value: record!.totalRevenueBooking,
                        color: '#0068FF'
                    }
                ]

                return (
                    `<div style="padding: 10px 20px; width: 300px; ">
                        <div style="margin-bottom: 10px">
                            <span style="color: #000">${title}</span>
                        </div>
                        <div style="margin-bottom: 8px">
                            <ul style="margin-left: 0px">
                                ${overviewOfColumn?.map((item) => {
                                    return `
                                        <li style="margin-bottom: 5px; color: ${item.color}">
                                            <div style="display: flex; justify-content: space-between">
                                                <span style="color: #000; font-weight: 500">${item.name}: </span>
                                                <div>
                                                    <span style="color: #8F9BB2">${item.percent ? `(${item?.percent.toFixed(2)}%)`:'' }</span>
                                                    <span style="color: #0A1F44; font-weight: 500">${item.value?.toLocaleString('en-US')}</span>
                                                </div>
                                            </div>
                                        </li>
                                    `
                                }).join('')}
                            </ul>
                        </div>
                    `
                    )
                },
            },
        },
        style: {
            radiusTopLeft:10,
            radiusTopRight:10,
            insetLeft:10,
            insetRight:10,
            fill: (type : ColumnData) => {
                return type.color;
            },
        },
        scale: {
            x: { 
                type: 'band',
            },
            color : {
                range: ['#2970FF', '#F04438'],
            },
        },
        legend: {
            color: {
                layout: {
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    alignContent: 'flex-end',
                    alignItems: 'flex-end'
                },
                itemWidth: '100%',
            },
        },
    }), [dataChart, viewType]);

    return (<Column {...configChartColumn}/>);
}
