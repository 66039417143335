import { env } from '@env';
import { httpUtil } from '@/framework';
import { GetCustomerOverviewResponse } from './Types';
import { GetCustomerReportRequest } from '../Types';

export const getCustomerRevenueOverview = httpUtil.createHttpRequestMeta<
    GetCustomerReportRequest,
    GetCustomerOverviewResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/revenue-overview',
    method: 'GET',
    authentication: 'bearer'
});

