import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { FlexBox } from "@components/layouts";
import { AppAvatar } from "@components/avatar";
import { Text } from '@components/typo';
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { ServiceByStaffBookingDetailTable } from "./table-detail/ServiceByStaffBookingDetailTable";
import { getReportBookingByStaff, GetReportBookingByStaffItem } from "@/api";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
import { usePaginationBookingStaff } from "../hooks";
import { useCallback, useMemo } from "react";
import { formatDateTime } from "@/utils";

interface ServiceByStaffBookingTableProps {
    readonly dateRange: [Dayjs, Dayjs]
}

export function ServiceByStaffBookingTable({ dateRange }: ServiceByStaffBookingTableProps) {

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);

    const [pagination, setPagination] = usePaginationBookingStaff();

    const { data: bookingByStaffResponse, isFetching } = useHttpQuery(getReportBookingByStaff, {
        query: {
            startDate: formatDateTime(dateRange[0]),
            endDate: formatDateTime(dateRange[1]),
            salonId: salonActive?.id,
            search: pagination.staffSearch,
            page: pagination.staffPage,
            pageSize: pagination.staffPageSize,
            orderBy: pagination.staffOrderBy,
            orderDirection: pagination.staffOrderDirection,
            useCountTotal: true
        }
    })

    const showDetail = useCallback((title: string, staffId: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode:
                <p>
                    <span>Doanh thu dịch vụ theo nhân viên: </span>
                    <span style={{ color: 'grey', fontWeight: 400 }}>{title}</span>
                </p>,
            width: 850,
            content: <ServiceByStaffBookingDetailTable staffId={staffId} dateRange={dateRange} />
        });
    }, [dateRange, globalModal, setGlobalModal]);

    const columns: TableColumnsType<GetReportBookingByStaffItem> = useMemo(() => [
        {
            key: 'staffName',
            title: 'Tên nhân viên',
            dataIndex: 'staffName',
            render: (_, record) => {
                return <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                    <AppAvatar
                        size='sm'
                        url={record.avatar}
                    />
                    <Text maxWidth={350} fontWeight='bold'>{record.staffName}</Text>
                </FlexBox>;
            },
        },
        {
            key: 'totalQuantity',
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            render: (value: number) => value?.toLocaleString('en-US'),
            width: 250,
            sorter: true,
        },
        {
            key: 'totalRevenueBooking',
            title: 'Doanh thu',
            dataIndex: 'totalRevenueBooking',
            render: (value: number) => value?.toLocaleString('en-US'),
            width: 150,
            sorter: true,
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <TagViewDetailColumn showDetail={() => showDetail(record.staffName ?? '', record.staffId ?? 0)} />
                );
            },
        },
    ], [showDetail]);

    return (
        <TableCustom
            scroll={{ x: 800 }}
            titleTableTransCode="Dịch vụ theo nhân viên"
            onSearch={(value) => {
                setPagination({
                    ...pagination,
                    staffSearch: value
                });
            }}
            onPageChange={(nextPage) => {
                setPagination({
                    ...pagination,
                    staffPage: nextPage.toString()
                });
            }}
            onSort={(orderDirection, orderBy) => {
                setPagination({
                    ...pagination,
                    staffOrderDirection: orderDirection,
                    staffOrderBy: orderBy
                });
            }}
            loading={isFetching}
            pageInfo={bookingByStaffResponse?.meta}
            placeholderSearchTransCode='Tìm nhân viên'
            showHeader={true}
            columns={columns}
            dataSource={bookingByStaffResponse?.items}
        />
    )
}
