import { Dayjs } from 'dayjs';
import { Pie } from '@ant-design/plots';
import { Card } from 'antd';
import { useHttpQueries } from '@/hooks';
import { Block } from '@/components/layouts';
import { getCustomerGenderChart } from '@/api';
import { getCustomerGenderChartResource, getCustomerTotalByGender } from './@share/utils';
import { useMemo } from 'react';
import { useCustomerQueriesRequest } from '@/page/report/customer/@share/hooks';
import { dateHelper } from '@/helpers';

interface ReportCustomerGenderPieChartProps {
	readonly dateRange: [Dayjs, Dayjs];
	readonly salonBranchId?: number;
}

export const ReportCustomerGenderPieChart = (props: ReportCustomerGenderPieChartProps) => {
	const { dateRange, salonBranchId } = props;

	const queries = useCustomerQueriesRequest({
		requestsMeta: [getCustomerGenderChart],
		dateRange,
		salonBranchId,
	});

	const prevDateRange = useMemo(
		() => dateHelper.getDateRangePrevious(dateRange[0], dateRange[1]) as [Dayjs, Dayjs],
		[dateRange]
	);

	const prevQueries = useCustomerQueriesRequest({
		requestsMeta: [getCustomerGenderChart],
		dateRange: prevDateRange,
		salonBranchId,
	});

	const queyResults = useHttpQueries(queries);
	const prevQueyResults = useHttpQueries(prevQueries);

	const { dataChart, total } = getCustomerGenderChartResource(queyResults);
	const prevData = getCustomerTotalByGender(prevQueyResults)

	const config = useMemo(
		() => ({
			data: dataChart,
			angleField: 'value',
			colorField: 'type',
			tooltip: false,
			label: false,
			scale: { color: { range: ['#1890FF', '#69C0FF', '#BAE7FF'] } },
			legend: {
				color: {
					position: 'right',
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					itemValueText: (datum: any) => {
						const value = dataChart.find((o) => o?.type === datum.id)?.value ?? 0;

						const prevValue = prevData.find((o) => o.gender === datum.id)?.value ?? 0;

						const gdp = ((value - prevValue) / prevValue) * 100;

						return `${((value * 100) / total).toFixed(1)}% ${
							prevValue !== 0 ? `(${gdp > 0 ? '+' : ''}${gdp.toFixed(2)}%)` : ''
						}`;
					},
					colPadding: 210,
					rowPadding: 30,
					itemMarker: 'circle',
					itemLabelFontSize: 14,
					itemValueFontSize: 14,
					layout: {
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					},
					itemValueFill: '#F5222D',
				},
			},
		}),
		[dataChart, prevData, total]
	);

	return (
		<Block width='100%'>
			<Card key={prevData.toString() + total} style={{ height: '100%' }} title='Giới tính'>
				<Pie {...config} height={320} />
			</Card>
		</Block>
	);
};
