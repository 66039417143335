import { Line } from '@ant-design/charts';
import { useMemo } from 'react';
import { GetBookingOverviewChartResponseItem } from '@/api';
import dayjs from 'dayjs';

interface BookingLineChartProps {
    readonly bookingChartData: GetBookingOverviewChartResponseItem[];
}
interface ColumnData {
    readonly xField: string;
    readonly yField: number;
    readonly value?: number;
    readonly category?: string;
}

export default function BookingLineChart(props: BookingLineChartProps) {
    const { bookingChartData } = props;

    const dataChart = useMemo(() => {
        const data = bookingChartData?.map((item) => {
            return [
                {
                    xField: dayjs(item.dateTimeGroup).format('HH:mm'),
                    yField: item.totalBooking,
                    category: 'Tổng số lịch hẹn',
                    value: item.totalBooking,
                },
                {
                    xField: dayjs(item.dateTimeGroup).format('HH:mm'),
                    yField: item.totalBookingCancel,
                    category: 'Lịch hẹn đã hủy',
                    value: item.totalBookingCancel,
                },
            ] as ColumnData[]
        })

        return data?.flat() ?? [];
    },[bookingChartData])

    const configChartLine = {
        data: dataChart,
        xField: 'xField',
        yField: 'yField',
        colorField: 'category',
        scrollbar: { x: { ratio: 0.5 } },
        axis: {
            y: {
                labelFormatter: (v: unknown) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        scale: { color: { range: ['#F04438', '#17B26A'] } },
        style: {
            lineWidth: 2,
        },
    };

    return (<Line {...configChartLine} />);
}
