import { Dayjs } from 'dayjs';
import { CustomerReportBarChart } from '../CustomerReportBarChart';
import { getCustomerRankChart } from '@/api/report/customer/chart/getCustomerRankChart';

interface ReportCustomerRankBarChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salonBranchId?: number;
}

export const ReportCustomerRankBarChart = (props: ReportCustomerRankBarChartProps) => {
    const { dateRange, salonBranchId } = props;

    return (
       <CustomerReportBarChart 
            chartTitle='Hạng khách hàng'
            dateRange={dateRange}
            salonBranchId={salonBranchId}
            requestMeta={getCustomerRankChart}
       />
    );
}
