import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { useMemo } from "react";

export interface StatusBookingDetailData {
    readonly title?: string;
    readonly value?: string | number;
}

interface StatusBookingDetailTableProps {
    readonly dataDetails: StatusBookingDetailData[];
}

export function StatusBookingDetailTable({ dataDetails }: StatusBookingDetailTableProps) {

    const columns: TableColumnsType<StatusBookingDetailData> = useMemo(() => [
        {
            key: 'title',
            title: 'Chi nhánh',
            dataIndex: 'title',
        },
        {
            key: 'value',
            title: 'Số lượng',
            dataIndex: 'value',
            render: (value: number) => value?.toLocaleString('en-US'),
            width: 150
        }
    ], []);

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={dataDetails}
                showFooter={false}
            />
        </div>
    )
}
