import { Dayjs } from 'dayjs';
import { Card, Dropdown, MenuProps } from 'antd';
import { Block, FlexBox, Spacer } from '@components/layouts';
import { Text } from "@components/typo";
import { SelectField } from '@components/inputs/fields/SelectField';
import { AppButton } from '@components/buttons';
import { IconMore } from '@components/icons';
import { useMemo, useState } from 'react';
import { useHttpQueries } from '@/hooks';

import { useFormContext } from 'react-hook-form';
import RevenueLineChart from './chart/RevenueLineChart';
import RevenueColumnChart from './chart/RevenueColumnChart';
import { useQueriesRevenueChart, useQueriesRevenueChartEarlier } from './hooks';

const chartViewTypeOptions = [
    {
        label: "Doanh thu hoá đơn",
        value: null
    },
    {
        label: "Doanh dịch vụ",
        value: "SERVICE"
    },
    {
        label: "Doanh thu sản phẩm",
        value: "PRODUCT"
    }
]
const chartViewDateOptions = [
    {
        label: "Doanh thu 2 năm gần đây",
        value: 2
    },
    {
        label: "Doanh thu 3 năm gần đây",
        value: 3
    }
]

export interface ItemChart {
    readonly date: string;
    readonly value: number;
    readonly type?: string;
    readonly children?: {
        readonly name: string;
        readonly value: number;
        readonly percent?: number;
    }[];
}

enum ChartType {
    COLUMN = 0,
    LINE = 1,
}
interface ReportRevenueChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function ReportRevenueChart(props: ReportRevenueChartProps) {
    const { dateRange } = props;
    const [chartType, setChartType] = useState(ChartType.COLUMN);
    const { watch, setValue } = useFormContext();
    const numberYearQuery = watch('numberYear');

    const queriesCurrent = useQueriesRevenueChart(dateRange, watch('objectType'));
    const queriesYearEarlier = useQueriesRevenueChartEarlier(watch('objectType'), numberYearQuery);

    const queries = useMemo(() => {
        if(numberYearQuery) {
            return queriesYearEarlier;
        }
        return queriesCurrent;
    }, [numberYearQuery, queriesCurrent, queriesYearEarlier]);

    const revenueChartResponse = useHttpQueries(queries ?? []);
    const revenueData = useMemo(() => revenueChartResponse?.map((item) => item.data), [revenueChartResponse]);
    const isLoading = revenueChartResponse?.some((item) => item.isFetching);

    const items: MenuProps['items'] = useMemo(() => [
        {
            key: 1,
            label: (
                <Text 
                    onClick={() => {
                        setChartType(ChartType.COLUMN);
                        setValue('numberYear', undefined);
                    }}
                >
                    Hiển thị theo biểu đồ cột
                </Text>
            ),
        },
        {
            key: 2,
            label: (
                <Text onClick={() => setChartType(ChartType.LINE)}>Hiển thị theo biểu đồ đường</Text>
            )
        },
    ], [setValue, setChartType]);

    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Spacer top={16}>
                <Card>
                    <FlexBox direction='row' justifyContent='space-between' width={"100%"}>
                        <FlexBox gap={24} >
                            <Block width={300} >
                                <SelectField size='large'
                                    showSearch
                                    defaultValue={null}
                                    options={chartViewTypeOptions}
                                    name='objectType'
                                    placeholder="Select Type"
                                />
                            </Block>
                            {chartType == ChartType.LINE && (
                                <Block width={300}>
                                    <SelectField size='large'
                                        showSearch
                                        allowClear
                                        options={chartViewDateOptions}
                                        name='numberYear'
                                        placeholder="Select Date"
                                    />
                                </Block>
                            )}
                        </FlexBox>
                        <Dropdown trigger={['click']} menu={{ items }}>
                            <AppButton border={0}>
                                <IconMore />
                            </AppButton>
                        </Dropdown>
                    </FlexBox>
                    {chartType == ChartType.COLUMN && <RevenueColumnChart dateRange={dateRange} revenueData={revenueData} isLoading={isLoading} />}
                    {chartType == ChartType.LINE && <RevenueLineChart dateRange={dateRange} revenueData={revenueData} isLoading={isLoading} />}
                </Card>
            </Spacer>
        </Block>
    );
}
