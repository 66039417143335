import { ModalProps } from 'antd/lib/modal';

import { ModalCustom } from '../ModalCustom';
import { Block, FlexBox } from '@/components/layouts';
import { IconTrash } from '@/components/icons';
import { Text } from '@/components/typo';

interface ModalDeleteProps extends ModalProps {}

export const ModalDelete = (props: ModalDeleteProps) => {
    return (
        <ModalCustom
            open={props?.open}
            onCancel={props.onCancel}
            onOk={props.onOk}
            okButtonProps={{
                className: 'btn-delete',
            }}
            okText='Xoá'
            cancelText='Huỷ bỏ'
            showDivider={false}
            footer={props.footer}
        >
            
            <div className='content'>
                <Block
                    width={48}
                    height={48}
                    backgroundColor='#FEE4E2'
                    borderRadius={999}
                >
                    <FlexBox justifyContent='center' alignItems='center' height='100%' width='100%'>
                        <IconTrash color='red' />
                    </FlexBox>
                </Block>
                <FlexBox direction='column' gap={12}>
                    <Text color='#0A1F44' fontWeight={'500'} fontSize={16}>{props.title}</Text>
                    {props.children ? props.children : <Text color='#8F9BB2'>Lưu ý, hành động này không thể hoàn tác!</Text>}
                </FlexBox>
            </div>
        </ModalCustom>
    );
};
