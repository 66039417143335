import { env } from '@env';
import { httpUtil } from '@/framework';
import { GetCustomerBarChartResponse, GetCustomerChartRequest } from './Types';

export const getCustomerGroupChart = httpUtil.createHttpRequestMeta<
	GetCustomerChartRequest,
	GetCustomerBarChartResponse
>({
	baseUrl: env.orgServer,
	path: '/customers/group-chart',
	method: 'GET',
	authentication: 'bearer',
});
