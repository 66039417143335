
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { GetAllAccountResponse } from './getAllAccounts';

export interface DeleteAccountRequest extends HttpRequestData {
    readonly pathData: {
        readonly id: number;
    };
}

export const deleteAccount = httpUtil.createHttpRequestMeta<
    DeleteAccountRequest,
    GetAllAccountResponse
>({
    baseUrl: env.orgServer,
    path: '/accounts/:id',
    method: 'DELETE',
    authentication: 'bearer'
});
