import { Dayjs } from 'dayjs';
import { Block, FlexBox } from '@components/layouts';
import { ReportCustomerRankBarChart } from './children/customer-rank-report-chart';
import { ReportCustomerSourceBarChart } from './children/report-customer-source-bar-chart';
import { ReportCustomerGenderPieChart } from './children/report-customer-gender-chart';
import { ReportCustomerGroupBarChart } from './children/customer-group-report-chart/ReportCustomerGroupBarChart';

interface ReportCustomerSubChartProps {
	readonly dateRange: [Dayjs, Dayjs];
	readonly salonBranchId?: number;
}

export const ReportCustomerSubChart = (props: ReportCustomerSubChartProps) => {

	return (
		<Block overflow='hidden' maxWidth='100%'>
			<FlexBox direction='column' gap={20}>
				<FlexBox justifyContent='space-between' gap={20}>
					<ReportCustomerSourceBarChart
						salonBranchId={props.salonBranchId}
						dateRange={props.dateRange}
					/>
					<ReportCustomerGenderPieChart
						salonBranchId={props.salonBranchId}
						dateRange={props.dateRange}
					/>
				</FlexBox>
				<FlexBox justifyContent='space-between' gap={20}>
					<ReportCustomerGroupBarChart
						salonBranchId={props.salonBranchId}
						dateRange={props.dateRange}
					/>
					<ReportCustomerRankBarChart
						salonBranchId={props.salonBranchId}
						dateRange={props.dateRange}
					/>
				</FlexBox>
			</FlexBox>
		</Block>
	);
};
