import { getBookingSummaryStatus } from "@/api/report";
import { salonActiveState } from "@/components/state";
import { formatDateTime } from "@/utils";
import { Dayjs } from "dayjs";
import { useRecoilState } from "recoil";

export function useQueriesBookingSummaryStatus(dateRange: [Dayjs, Dayjs]) {
    const [salonActive] = useRecoilState(salonActiveState);

    const queriesBookingSummaryStatus = salonActive?.salonBranches?.map((branch) => ({
        requestMeta: getBookingSummaryStatus,
        requestData: {
            query: {
                startDate: formatDateTime(dateRange[0]),
                endDate: formatDateTime(dateRange[1]),
                salonId: salonActive.id,
                salonBranchId: branch.id
            }
        }
    }))

    return queriesBookingSummaryStatus ?? [];
}
