
import { env } from '@env';
import { HttpRequestData, PaginationResponse, httpUtil } from '@/framework';

export interface GetAllAccountRequest extends HttpRequestData {
}

export interface GetAllAccountResponse {
    readonly id: number;
    readonly email: string;
    readonly name: string;
    readonly rootAccount?: boolean;
    readonly orgAccountSalons?: OrgAccountSalon[],
}

export interface OrgAccountSalon {
    readonly salonId: number;
    readonly orgAccountId: number;
}

export const getAllAccounts = httpUtil.createHttpRequestMeta<
    GetAllAccountRequest,
    PaginationResponse<GetAllAccountResponse>
>({
    baseUrl: env.orgServer,
    path: '/accounts',
    method: 'GET',
    authentication: 'bearer'
});
