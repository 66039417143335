export const OtherPaymentIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.625 14.73C18.585 15.06 19.875 15.765 19.875 17.97C19.875 19.845 18.39 21.39 16.575 21.39H16.125V21.765C16.125 22.38 15.615 22.89 15 22.89C14.385 22.89 13.875 22.38 13.875 21.765V21.39H13.755C11.76 21.39 10.125 19.71 10.125 17.64C10.125 17.01 10.635 16.5 11.25 16.5C11.865 16.5 12.375 17.01 12.375 17.625C12.375 18.45 12.99 19.125 13.755 19.125H13.875V15.795L12.375 15.27C11.415 14.94 10.125 14.235 10.125 12.03C10.125 10.155 11.61 8.61 13.425 8.61H13.875V8.25C13.875 7.635 14.385 7.125 15 7.125C15.615 7.125 16.125 7.635 16.125 8.25V8.625H16.245C18.24 8.625 19.875 10.305 19.875 12.375C19.875 12.99 19.365 13.5 18.75 13.5C18.135 13.5 17.625 12.99 17.625 12.375C17.625 11.55 17.01 10.875 16.245 10.875H16.125V14.205L17.625 14.73Z" fill="#528BFF"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.39 1.44C19.44 0.525 17.28 0 15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 12.72 29.415 10.2 28.5 8.25C26.748 4.938 24.1788 2.74857 21.39 1.44ZM19.875 17.97C19.875 15.765 18.585 15.06 17.625 14.73L16.125 14.205V10.875H16.245C17.01 10.875 17.625 11.55 17.625 12.375C17.625 12.99 18.135 13.5 18.75 13.5C19.365 13.5 19.875 12.99 19.875 12.375C19.875 10.305 18.24 8.625 16.245 8.625H16.125V8.25C16.125 7.635 15.615 7.125 15 7.125C14.385 7.125 13.875 7.635 13.875 8.25V8.61H13.425C11.61 8.61 10.125 10.155 10.125 12.03C10.125 14.235 11.415 14.94 12.375 15.27L13.875 15.795V19.125H13.755C12.99 19.125 12.375 18.45 12.375 17.625C12.375 17.01 11.865 16.5 11.25 16.5C10.635 16.5 10.125 17.01 10.125 17.64C10.125 19.71 11.76 21.39 13.755 21.39H13.875V21.765C13.875 22.38 14.385 22.89 15 22.89C15.615 22.89 16.125 22.38 16.125 21.765V21.39H16.575C18.39 21.39 19.875 19.845 19.875 17.97Z" fill="#B2CCFF"/>
            <path d="M16.125 19.1262H16.575C17.16 19.1262 17.625 18.6012 17.625 17.9562C17.625 17.1462 17.4 17.0262 16.89 16.8462L16.125 16.5762V19.1262Z" fill="#B2CCFF"/>
            <path d="M12.375 12.045C12.375 12.855 12.6 12.975 13.11 13.155L13.875 13.425V10.875H13.425C12.855 10.875 12.375 11.4 12.375 12.045Z" fill="#B2CCFF"/>
        </svg>
    );
}
