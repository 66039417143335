
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';

export interface UpdateAccountRequest extends HttpRequestData {
    readonly body: {
        readonly name: string;
        readonly email: string;
        readonly password?: string;
        readonly salonIds?: number[];
        readonly phoneNumber?: string;
    };
}

export interface UpdateAccountResponse {

}

export const updateAccount = httpUtil.createHttpRequestMeta<
    UpdateAccountRequest,
    UpdateAccountResponse
>({
    baseUrl: env.orgServer,
    path: '/accounts/:id',
    method: 'PATCH',
    authentication: 'bearer'
});
