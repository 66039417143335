export enum CustomerOverviewType {
	Total = 0,
	New = 1,
	Return = 2,
	WalkIn = 3,
    Revenue = 4
}

export interface GetCustomerOverviewResponse {
	readonly value: number;
	readonly overviewType: CustomerOverviewType;
}

