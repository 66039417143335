
import { env } from '@env';
import { HttpRequestData, PaginationResponse, httpUtil } from '@/framework';
import { Salon } from '../accounts';

export interface GetAllSalonsRequest extends HttpRequestData {
}

export const getAllSalons = httpUtil.createHttpRequestMeta<
    GetAllSalonsRequest,
    PaginationResponse<Salon>
>({
    baseUrl: env.orgServer,
    path: '/salons',
    method: 'GET',
    authentication: 'bearer'
});
