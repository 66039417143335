import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
export interface FormValue<T> {
    readonly [key: string]: T;
}

export type RefMethodFormType = React.MutableRefObject<UseFormReturn<FormValue<unknown>> | null>;

interface FormBaseProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly onSubmit: any;
    readonly defaultValues?: unknown;
    readonly children: ((form: ReturnType<typeof useForm>) => React.ReactNode) | React.ReactNode;
    readonly formRef?: RefMethodFormType;
}

export const FormBase = (props: FormBaseProps) => {
    const { formRef } = props;

    const form = useForm<FormValue<unknown>>({
        shouldUseNativeValidation: true,
        defaultValues: props.defaultValues ?? {},
    });

    if (formRef) {
        formRef.current = form;
    }

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(props.onSubmit)}>
                {props.children instanceof Function ? props.children(form) : props.children}
            </form>
        </FormProvider>
    );
};