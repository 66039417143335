import { useSearchParams } from "@/hooks";

interface PaginationObjectType {
    readonly objectTypeSearch: string;
    readonly objectTypePage: string;
    readonly objectTypePageSize: string;
    readonly objectTypeOrderDirection?: string;
    readonly objectTypeOrderBy?: string;
}

export function usePaginationObjectType() {
    const initPagination: PaginationObjectType = {
        objectTypeSearch: '',
        objectTypePage: '1',
        objectTypePageSize: '20',
        objectTypeOrderDirection: '',
        objectTypeOrderBy:''
    }

    return useSearchParams<PaginationObjectType>(initPagination, [
        'objectTypeOrderBy',
        'objectTypeOrderDirection',
        'objectTypePage',
        'objectTypePageSize',
        'objectTypeSearch'
    ]);
}