import { Dayjs } from 'dayjs';
import { Card } from 'antd';
import { Block, Spacer } from '@components/layouts';
import { Text } from "@components/typo";
import { useQueriesBookingChart } from './hooks';
import { useHttpQueries } from '@/hooks';
import { useMemo } from 'react';
import { bookingReportUtils } from './utils';
import { GetBookingOverviewChartResponseItem } from '@/api';
import BookingColumnChart from './chart/BookingColumnChart';
import BookingLineChart from './chart/BookingLineChart';
import { chartHelper } from '@/helpers';
import { ViewType } from '@/shared/enum';

interface BookingReportChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}
export default function BookingReportChart(props: BookingReportChartProps) {
    const { dateRange } = props;

    const queriesBookingChart = useQueriesBookingChart(dateRange);
    const viewType = chartHelper.getViewTypeChart(dateRange);

    const queriesResult = useHttpQueries(queriesBookingChart);
    const queriesResultLoading = useMemo(() => queriesResult.some((query) => query.isLoading), [queriesResult]);
    const queriesResultData = useMemo(() => queriesResult.map((query) => query.data), [queriesResult]);

    const bookingChartData = useMemo(() => {
        const data = queriesResultData?.[0]?.map((item) => {
            const bookingDataChartInDay = queriesResultData?.flat().filter((item1) => item1?.dateTimeGroup === item?.dateTimeGroup);

            return {
                dateTimeGroup: item.dateTimeGroup,
                totalBooking: bookingReportUtils.getTotalBookingOverviewChart(bookingDataChartInDay, 'totalBooking'),
                totalBookingCancel: bookingReportUtils.getTotalBookingOverviewChart(bookingDataChartInDay, 'totalBookingCancel'),
                totalBookingOnline: bookingReportUtils.getTotalBookingOverviewChart(bookingDataChartInDay, 'totalBookingOnline'),
                totalBookingSalon: bookingReportUtils.getTotalBookingOverviewChart(bookingDataChartInDay, 'totalBookingSalon'),
                totalRevenueBooking: bookingReportUtils.getTotalBookingOverviewChart(bookingDataChartInDay, 'totalRevenueBooking'),
            }
        }) as unknown as GetBookingOverviewChartResponseItem[];

        return data;
    },[queriesResultData])

    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Spacer top={16}>
                <Card>
                    <Text fontSize={18} fontWeight={600}>Tổng số lịch hẹn</Text>
                    {!queriesResultLoading && (
                        <>
                            {viewType === ViewType.Hour && (
                                <BookingLineChart bookingChartData={bookingChartData} />
                            )}
                            {viewType !== ViewType.Hour && (
                                <BookingColumnChart  bookingChartData={bookingChartData} dateRange={dateRange} />
                            )}
                        </>
                    )}
                </Card>
            </Spacer>
        </Block>
    );
}
