import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";
import { getReportBookingByServiceCategoryDetail, GetReportBookingByServiceCategoryDetailItem } from "@/api";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
import { useMemo } from "react";
import { formatDateTime } from "@/utils";

interface ServiceCategoryByBookingDetailTableProps {
    readonly serviceCategoryId: number;
    readonly dateRange: [Dayjs, Dayjs];
}

export function ServiceCategoryByBookingDetailTable({serviceCategoryId, dateRange} : ServiceCategoryByBookingDetailTableProps) {

    const [salonActive] = useRecoilState(salonActiveState);

    const { data: bookingByServiceCategoryDetailResponse, isLoading } = useHttpQuery(getReportBookingByServiceCategoryDetail, {
        query: {
            serviceCategoryId,
            startDate: formatDateTime(dateRange[0]),
            endDate: formatDateTime(dateRange[1]),
            salonId: salonActive?.id,
        }
    })
   
    const columns: TableColumnsType<GetReportBookingByServiceCategoryDetailItem> = useMemo(() => [
        {
            title: 'Chi nhánh',
            dataIndex: 'salonBranchId',
            key: 'salonBranchId',
            render: (salonBranchId: number) => salonActive?.salonBranches?.find((branch) => branch.id === salonBranchId)?.name,
        },
        {
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            render: (totalQuantity: number) => totalQuantity?.toLocaleString('en-US'),
            width: 250
        },
        {
            title: 'Doanh thu',
            dataIndex: 'totalRevenueBooking',
            key: 'totalRevenueBooking',
            render: (totalRevenueBooking: number) => totalRevenueBooking?.toLocaleString('en-US'),
            width: 150
        }
    ], [salonActive]);

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                loading={isLoading} 
                showHeader={false}
                columns={columns}
                dataSource={bookingByServiceCategoryDetailResponse?.items}
                pageInfo={bookingByServiceCategoryDetailResponse?.meta}
            />
        </div>
    )
}
