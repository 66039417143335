import { useSearchParams } from "@/hooks";

interface PaginationBookingService {
    readonly serviceSearch: string;
    readonly servicePage: string;
    readonly servicePageSize: string;
    readonly serviceOrderDirection?: string;
    readonly serviceOrderBy?: string;
}

export function usePaginationBookingService() {
    const initPagination: PaginationBookingService = {
        serviceSearch: '',
        servicePage: '1',
        servicePageSize: '20',
        serviceOrderDirection: '',
        serviceOrderBy:''
    }

    return useSearchParams<PaginationBookingService>(initPagination, [
        'serviceOrderBy',
        'serviceOrderDirection',
        'servicePage',
        'servicePageSize',
        'serviceSearch'
    ]);
}