import { FormBase } from "@components/base";
import { Block, FlexBox, Spacer } from "@components/layouts";
import { Header } from "@components/typo";
import BookingReportOverview from "./BookingReportOverview";
import BookingReportChart from "./BookingReportChart";
import BookingReportTabs from "./BookingReportTabs";
import { chartHelper } from "@/helpers";
import { useOutletContext } from "react-router-dom";
import { Dayjs } from "dayjs";



export function BookingReport() {
    const { dateRange } = useOutletContext<{ dateRange: [Dayjs, Dayjs] }>();
    const viewType = chartHelper.getViewTypeChart(dateRange);
   
    return (
        <FormBase onSubmit={() => { }}>
            <Block backgroundColor='white' className="tab-content">
                <FlexBox direction='column'>
                    <Spacer bottom={16}>
                        <Header level={3}>
                            Báo cáo Lịch hẹn
                        </Header>
                    </Spacer>
                    <BookingReportOverview dateRange={dateRange}></BookingReportOverview>
                    {typeof viewType !== 'undefined' && <BookingReportChart dateRange={dateRange} />}
                    <BookingReportTabs dateRange={dateRange} />
                </FlexBox>
            </Block>
        </FormBase>
    )
}
