import { Tabs } from 'antd';
import { Dayjs } from 'dayjs';
import { FlexBox, Spacer } from '@components/layouts';
import { OverviewBookingTable } from './table/OverviewBookingTable';
import { ServiceByBookingTable } from './table/ServiceByBookingTable';
import { ServiceCategoryByBookingTable } from './table/ServiceCategoryByBookingTable';
import { StatusBookingTable } from './table/StatusBookingTable';
import { ServiceByStaffBookingTable } from './table/ServiceByStaffBookingTable';
import { salonActiveState } from '@/components/state';
import { useRecoilState } from 'recoil';
import { useMemo } from 'react';

interface BookingReportTabsProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function BookingReportTabs(props: BookingReportTabsProps) {
    const { dateRange } = props;
    const [salonActive] = useRecoilState(salonActiveState);

    const tabsContent = useMemo(() => {
        let items = [
            {
                key: '1',
                label: "Tổng quan",
                children: <OverviewBookingTable dateRange={dateRange} />,
            },
        ]

        if(salonActive) {
            items = [...items, {
                key: '2',
                label: 'Trạng thái',
                children: (
                    <FlexBox gap={24} direction='column'>
                        <StatusBookingTable dateRange={dateRange} />
                    </FlexBox>
                ),
            },
            {
                key: '3',
                label: 'Dịch vụ',
                children: (
                    <FlexBox gap={24} direction='column'>
                        <ServiceByBookingTable dateRange={dateRange} />
                        <ServiceByStaffBookingTable dateRange={dateRange} />
                        <ServiceCategoryByBookingTable dateRange={dateRange} />
                    </FlexBox>
                ),
            }]
        }

        return items;
    }, [dateRange, salonActive]);

    return (
        <Spacer top={24} >
            <Tabs defaultActiveKey='1' items={tabsContent} />
        </Spacer>
    );
}
