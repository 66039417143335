
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';

export interface GetBookingOverviewRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly salonBranchId?: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetBookingOverviewResponse {
    readonly totalBooking?: number;
    readonly totalBookingCancel?: number;
    readonly totalBookingOnline?: number;
    readonly totalBookingSalon?: number;
    readonly totalRevenueBooking?: number;
    readonly salonId?: number;
    readonly salonBranchId?: number;
}

export const getBookingOverview = httpUtil.createHttpRequestMeta<
    GetBookingOverviewRequest,
    GetBookingOverviewResponse
>({
    baseUrl: env.orgServer,
    path: '/bookings/overview',
    method: 'GET',
    authentication: 'bearer'
});
