import { useSearchParams } from "@/hooks";

interface PaginationPaymentMethodType {
    readonly paymentMethodSearch: string;
    readonly paymentMethodPage: string;
    readonly paymentMethodPageSize: string;
    readonly paymentMethodOrderDirection?: string;
    readonly paymentMethodByOrderBy?: string;
}

export function usePaginationPaymentMethod() {
    const initPagination: PaginationPaymentMethodType = {
        paymentMethodSearch: '',
        paymentMethodPage: '1',
        paymentMethodPageSize: '20',
        paymentMethodOrderDirection: '',
        paymentMethodByOrderBy:''
    }

    return useSearchParams<PaginationPaymentMethodType>(initPagination, [
        'paymentMethodByOrderBy',
        'paymentMethodOrderDirection',
        'paymentMethodPage',
        'paymentMethodPageSize',
        'paymentMethodSearch'
    ]);
}