import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { PaymentMethodColumn } from "./columns/PaymentMethodColumn";
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { RevenueByPaymentMethodDetailTable } from "./table-details/RevenueByPaymentMethodDetailTable";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
import { getRevenueOfPaymentMethod, GetRevenueOfPaymentMethodResponse } from "@/api";
import { usePaginationPaymentMethod } from "../hooks";
import { useCallback, useMemo } from "react";
import { formatDateTime } from "@/utils";

const paymentMethodSort = [
    'CASH_CARD',
    'CASH',
    'BANK_TRANSFER',
    'SWIPE_CARD',
    'DEBIT',
    'LOYALTY',
    'OTHER'
];

interface RevenueByPaymentMethodTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export function RevenueByPaymentMethodTable(props: RevenueByPaymentMethodTableProps) {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);

    const [pagination, setPagination] =usePaginationPaymentMethod();

    const {data : revenueOfPaymentMethodResponse, isFetching} = useHttpQuery(getRevenueOfPaymentMethod, {
        query: {
            startDate: formatDateTime(props.dateRange[0]),
            endDate: formatDateTime(props.dateRange[1]),
            salonId: salonActive?.id ?? 0,
            orderDirection: pagination.paymentMethodOrderDirection,
            orderBy: pagination.paymentMethodByOrderBy,
        }
    });

    const showDetail = useCallback((title: string, paymentMethodId: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: PaymentMethodColumn.getPaymentMethod(title).name,
            width: 850,
            content: <RevenueByPaymentMethodDetailTable paymentMethodId={paymentMethodId} dateRange={props.dateRange}/>
        });
    }, [globalModal, setGlobalModal, props.dateRange]);

    const columns: TableColumnsType<GetRevenueOfPaymentMethodResponse> = useMemo(() => [
        {
            key: 'paymentMethodCode',
            title: 'Phương thức thanh toán',
            dataIndex: 'paymentMethodCode',
            render: (_, record) => {
                return <PaymentMethodColumn paymentMethodCode={record.paymentMethodCode ?? ''} />
            },
        },
        {
            key: 'totalQuantity',
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            width: 250,
            sorter: true,
            render: (value: number) => value.toLocaleString('en-US'),
        },
        {
            key: 'totalRevenue',
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            render: (value: number) => value.toLocaleString('en-US'),
            width: 150,
            sorter: true
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <TagViewDetailColumn showDetail={()=> showDetail(record.paymentMethodCode!, record.paymentMethodId!)} />
                );
            },
        },
    ], [showDetail]);

    const revenuePaymentMethodSort = useMemo(()=> revenueOfPaymentMethodResponse?.items?.sort((a, b) => {
        return paymentMethodSort.indexOf(a.paymentMethodCode!) - paymentMethodSort.indexOf(b.paymentMethodCode!);
    }), [revenueOfPaymentMethodResponse]);

    return (
        <TableCustom
            loading={isFetching}
            scroll={{ x: 800 }}
            titleTableTransCode="Doanh thu theo doanh mục sản phẩm"
            placeholderSearchTransCode="Tìm doanh mục"
            showHeader={true}
            columns={columns}
            onSort={(orderDirection, orderBy) => {
                setPagination({
                    ...pagination,
                    paymentMethodOrderDirection: orderDirection,
                    paymentMethodByOrderBy: orderBy
                });
            }}
            dataSource={revenuePaymentMethodSort}
        />
    )
}
