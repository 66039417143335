import { Dayjs } from 'dayjs';
import { CustomerReportBarChart } from '../CustomerReportBarChart';
import { getCustomerSourceChart } from '@/api';

interface ReportCustomerSourceBarChartProps {
	readonly dateRange: [Dayjs, Dayjs];
	readonly salonBranchId?: number;
}
export const ReportCustomerSourceBarChart = (props: ReportCustomerSourceBarChartProps) => {
	const { dateRange, salonBranchId } = props;

	return (
		<CustomerReportBarChart
			dateRange={dateRange}
			chartTitle='Nguồn khách hàng'
			salonBranchId={salonBranchId}
			requestMeta={getCustomerSourceChart}
		/>
	);
};
