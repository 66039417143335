
import { env } from '@env';
import { HttpRequestData, httpUtil, PaginationResponse } from '@/framework';

export interface GetReportBookingByServiceRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetReportBookingByServiceItem  {
    readonly totalRevenueBooking: number;
    readonly totalQuantity: number;
    readonly serviceName?: string;
    readonly serviceId?: number;
    readonly salonId?: number;
    readonly salonBranchId?: number;
}

export const getReportBookingByService = httpUtil.createHttpRequestMeta<
    GetReportBookingByServiceRequest,
    PaginationResponse<GetReportBookingByServiceItem>
>({
    baseUrl: env.orgServer,
    path: '/bookings/by-service',
    method: 'GET',
    authentication: 'bearer'
});
