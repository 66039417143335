
import { env } from '@env';
import { HttpRequestData, httpUtil, PaginationResponse } from '@/framework';

export interface GetReportBookingByServiceCategoryRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetReportBookingByServiceCategoryItem  {
    readonly totalRevenueBooking: number;
    readonly totalQuantity: number;
    readonly serviceCategoryName?: string;
    readonly serviceCategoryId?: number;
    readonly salonId?: number;
    readonly salonBranchId?: number;
}

export const getReportBookingByServiceCategory = httpUtil.createHttpRequestMeta<
    GetReportBookingByServiceCategoryRequest,
    PaginationResponse<GetReportBookingByServiceCategoryItem>
>({
    baseUrl: env.orgServer,
    path: '/bookings/by-service-category',
    method: 'GET',
    authentication: 'bearer'
});
