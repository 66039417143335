import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { ServiceByBookingDetailTable } from "./table-detail/ServiceByBookingDetailTable";
import { Dayjs } from "dayjs";
import { useHttpQuery } from "@/hooks";
import { getReportBookingByService, GetReportBookingByServiceItem } from "@/api";
import { usePaginationBookingService } from "../hooks";
import { useCallback, useMemo } from "react";
import { formatDateTime } from "@/utils";
export interface ServiceByBookingTableProps {
    readonly dateRange: [Dayjs, Dayjs]
}

export function ServiceByBookingTable({ dateRange }: ServiceByBookingTableProps) {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);

    const [pagination, setPagination] = usePaginationBookingService();

    const { data: bookingByServiceResponse, isFetching } = useHttpQuery(getReportBookingByService, {
        query: {
            startDate: formatDateTime(dateRange[0]),
            endDate: formatDateTime(dateRange[1]),
            salonId: salonActive?.id,
            search: pagination.serviceSearch,
            page: pagination.servicePage,
            pageSize: pagination.servicePageSize,
            orderBy: pagination.serviceOrderBy,
            orderDirection: pagination.serviceOrderDirection,
            useCountTotal: true
        }
    })

    const showDetail = useCallback((title: string, serviceId: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <ServiceByBookingDetailTable serviceId={serviceId} dateRange={dateRange} />
        });
    }, [dateRange, globalModal, setGlobalModal]);

    const columns: TableColumnsType<GetReportBookingByServiceItem> = useMemo(() => [
        {
            key: 'serviceName',
            title: 'Tên dịch vụ',
            dataIndex: 'serviceName',
        },
        {
            key: 'totalQuantity',
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            render: (value: number) => value?.toLocaleString('en-US'),
            sorter: true,
            width: 250
        },
        {
            key: 'totalRevenueBooking',
            title: 'Doanh thu',
            dataIndex: 'totalRevenueBooking',
            render: (value: number) => value?.toLocaleString('en-US'),
            sorter: true,
            width: 150
        },
        {
            title: "Liên kết",
            key: 'link',
            width: 150,
            render: (_, record) => (
                <TagViewDetailColumn showDetail={() => showDetail(record.serviceName ?? '', record.serviceId ?? 0)} />
            ),
        },
    ], [ showDetail]);

    

    return (
        <TableCustom
            scroll={{ x: 800 }}
            loading={isFetching}
            placeholderSearchTransCode='Tìm dịch vụ'
            onSearch={(value) => {
                setPagination({
                    ...pagination,
                    serviceSearch: value
                })
            }}
            onPageChange={(nextPage) => {
                setPagination({
                    ...pagination,
                    servicePage: nextPage.toString()
                });
            }}
            onSort={(orderDirection, orderBy) => {
                setPagination({
                    ...pagination,
                    serviceOrderDirection: orderDirection,
                    serviceOrderBy: orderBy
                });
            }}
            showHeader={true}
            titleTableTransCode="Dịch vụ"
            columns={columns}
            pageInfo={bookingByServiceResponse?.meta}
            dataSource={bookingByServiceResponse?.items}
        />
    )
}
