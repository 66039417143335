import { useSearchParams } from "@/hooks";

interface PaginationRevenueStaffType {
    readonly staffSearch: string;
    readonly staffPage: string;
    readonly staffPageSize: string;
    readonly staffOrderDirection?: string;
    readonly staffByOrderBy?: string;
}

export function usePaginationRevenueStaff() {
    const initPagination: PaginationRevenueStaffType = {
        staffSearch: '',
        staffPage: '1',
        staffPageSize: '20',
        staffOrderDirection: '',
        staffByOrderBy:''
    }

    return useSearchParams<PaginationRevenueStaffType>(initPagination, [
        'staffByOrderBy',
        'staffOrderDirection',
        'staffPage',
        'staffPageSize',
        'staffSearch'
    ]);
}