
import { env } from '@env';
import { httpUtil } from '@/framework';
import { GetCustomerOverviewResponse } from './Types';
import { GetCustomerReportRequest } from '../Types';

export const getCustomerWalkInOverview = httpUtil.createHttpRequestMeta<
    GetCustomerReportRequest,
    GetCustomerOverviewResponse
>({
    method: 'GET',
    baseUrl: env.orgServer,
    authentication: 'bearer',
    path: '/customers/walk-in-overview'
});
