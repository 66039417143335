import { FormBase, RefMethodFormType } from '../../base';
import { FlexBox, Spacer } from '../../layouts';
import { InputField } from '../../inputs/fields/InputField';
import { AppButton } from '../../buttons';
import React, { useMemo, useRef } from 'react';
import { SelectField } from '@/components/inputs/fields/SelectField';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { globalModalState, salonsState } from '@/components/state';
import { createAccount, updateAccount } from '@/api';
import { useHttpCommand } from '@/hooks';
interface AccountFormValues {
    readonly id?: number;
    readonly name: string;
    readonly email: string;
    readonly password?: string;
    readonly salonIds?: number[];
    readonly phoneNumber?: string;
    readonly rootAccount?: boolean;
}

interface AccountFormProps {
    readonly defaultValues?: Partial<AccountFormValues>;
    readonly onSuccess?: () => void;
}

const accountRules = {
    name: {
        required: 'Yêu cầu nhập dữ liệu',
        maxLength: {
            value: 100,
            message: 'Tối đa 100 kí tự',
        },
    },
    salonIds: {
        required: 'Yêu cầu nhập dữ liệu',
    },
    password: {
        required: 'Yêu cầu nhập dữ liệu',
    },
    email: {
        required: 'Yêu cầu nhập dữ liệu',
        pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
            message: 'Yêu cầu nhập đúng định dạng email',
        },
    },
};

export const AccountForm = (props: AccountFormProps) => {
    const [salons] = useRecoilState(salonsState);
    const resetModalState = useResetRecoilState(globalModalState);
    const formRef : RefMethodFormType = useRef(null);

    const salonOptions = useMemo(() => {
        const options = salons?.map(salon => ({
            label: salon.name,
            value: salon.id
        })) ?? [];

        return options;
    }
    , [salons]);

    const { mutateAsync: createAccountMutate , isPending: isCreateLoading } = useHttpCommand(createAccount, {
        onSuccess: () => {
            props?.onSuccess?.();
            resetModalState();
        },
        onError() {
            formRef.current?.setError('email', {
                type: 'validate',
                message: 'Địa chỉ email đã tồn tại',
            });
        },
    });

    const { mutateAsync: updateAccountMutate, isPending: isUpdateLoading } = useHttpCommand(updateAccount, {
        onSuccess: () => {
            props?.onSuccess?.();
            resetModalState();
        },
        onError() {
            formRef.current?.setError('email', {
                type: 'validate',
                message: 'Địa chỉ email đã tồn tại',
            });
        },
    });
   
    const onSubmit = React.useCallback(async (data: AccountFormValues) => {
            if(props?.defaultValues?.id) {
               return await updateAccountMutate(
                    {
                        body: data,
                        pathData: {id: props?.defaultValues?.id}
                    },
                );

            }

            await createAccountMutate(
                {
                    body: data,
                },
            );
        },
        [createAccountMutate, updateAccountMutate, props?.defaultValues]
    );

    console.log("defaultValues", props.defaultValues);
    
    return (
        <FormBase formRef={formRef} onSubmit={onSubmit} defaultValues={props.defaultValues}>
            <FlexBox direction='column' gap={22} >
                <Spacer />
                <InputField
                    name='name'
                    label="Tên tài khoản"
                    rules={accountRules.name}
                    placeholder="Nhập tên tài khoản"
                />
                <InputField
                    name='email'
                    rules={accountRules.email}
                    label="Email"
                    placeholder="Nhập tài khoản Email"
                />
                <InputField
                    type='password'
                    name='password'
                    rules={accountRules.password}
                    label='Mật khẩu'
                    placeholder='Nhập mật khẩu'
                />
                {!props.defaultValues?.rootAccount && (
                    <SelectField
                        showSearch
                        options={salonOptions}
                        mode='multiple'
                        name='salonIds'
                        rules={accountRules.salonIds}
                        allowClear
                        label="Salon"
                        placeholder="Chọn salon"
                    />
                )}
                <InputField
                    name='phoneNumber'
                    label='Số điện thoại'
                    placeholder='Nhập số điện thoại'
                />
                <FlexBox justifyContent='flex-end' gap={8}>
                    <AppButton
                        type='default'
                        text='Huỷ bỏ'
                        onClick={resetModalState}
                    />
                    <AppButton
                        htmlType='submit'
                        type='primary'
                        text={props.defaultValues?.id ? 'Chỉnh sửa' : 'Tạo'}
                        loading={isCreateLoading || isUpdateLoading}
                    />
                </FlexBox>
            </FlexBox>
        </FormBase>
    );
};
