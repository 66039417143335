
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';

export interface GetBookingSummaryRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly salonBranchId?: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetBookingSummaryResponse {
    readonly totalBooking?: number;
    readonly totalCustomers?: number;
    readonly totalBookingNotCome?: number;
    readonly totalServices?: number;
    readonly totalRevenueBooking?: number;
    readonly salonId?: number;
    readonly salonBranchId?: number;
}

export const getBookingSummary = httpUtil.createHttpRequestMeta<
    GetBookingSummaryRequest,
    GetBookingSummaryResponse
>({
    baseUrl: env.orgServer,
    path: '/bookings/summary',
    method: 'GET',
    authentication: 'bearer'
});
