import React, { useMemo, useCallback } from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import { FlexBox } from '../layouts';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/vi';
import locale from 'antd/es/locale/vi_VN';
import './DatePickerRange.scss'
import { dateHelper } from '@/helpers';
import { TimeRangePickerProps } from 'antd/lib';
dayjs.locale('vi')

const { RangePicker } = DatePicker;

interface DatePickerRangeProps extends Omit<TimeRangePickerProps, 'onChange' | 'defaultValue'> {
    onChange: (dates: [Dayjs, Dayjs]) => void;
    defaultValue?: [Dayjs, Dayjs];
    limitDateRange?: {
        limitUnit: 'years' | 'months' | 'days';
        limitValue: number;
    }
}

export const DatePickerRange = (props: DatePickerRangeProps) => {
    const { onChange, defaultValue, limitDateRange } = props;
    const [value, setValue] = React.useState<[Dayjs, Dayjs]>(defaultValue ?? [dayjs(), dayjs()]);
    const [selectedDates, setSelectedDates] = React.useState<[Dayjs | null, Dayjs | null]>([null, null]);

    const rangePresets: TimeRangePickerProps['presets'] = useMemo(() => [
        { label: 'Hôm Nay', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
        { label: 'Hôm qua', value: [dayjs().add(-1, 'd').startOf('day'), dayjs().add(-1, 'd').endOf('day')] },
        { label: 'Tuần này', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
        { label: 'Tuần trước', value: [dayjs().add(-1, 'w').startOf('week'), dayjs().add(-1, 'w').endOf('week')] },
        { label: 'Tháng này', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
        { label: 'Tháng trước', value: [dayjs().add(-1, 'M').startOf('month'), dayjs().add(-1, 'M').endOf('month')] },
        { label: 'Năm này', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
        { label: 'Năm trước', value: [dayjs().add(-1, 'y').startOf('year'), dayjs().add(-1, 'y').endOf('year')] },
    ], []);

    const presetLabel = dateHelper.getLabelPresets(rangePresets, value);


   const handleOpenChange = useCallback((open: boolean) => {
        if(!open) {
            setSelectedDates([null, null]);
        }
   }, [setSelectedDates]);

   const handleDisabledDate = useCallback((current: Dayjs) => {
        if (!limitDateRange) return false;

        const { limitUnit, limitValue } = limitDateRange;
        const [start, end] = selectedDates;

        if (start && !end) {
            const tooLate = current.diff(start, limitUnit) >= limitValue;
            const tooEarly = start.diff(current, limitUnit) >= limitValue;
            return tooEarly || tooLate;
        }

        if (end && !start) {
            const tooLate = current.diff(end, limitUnit) >= limitValue;
            const tooEarly = end.diff(current, limitUnit) >= limitValue;
            return tooEarly || tooLate;
        }

        return false;
    }, [limitDateRange, selectedDates]);


    return (
        <FlexBox className='date-picker-range'>
            <ConfigProvider locale={locale}>
                {presetLabel && 
                    <span className='date-picker-range__label_preset'>
                        {presetLabel}
                    </span>
                }
                <RangePicker
                    presets={rangePresets}
                    placement="bottomLeft"
                    width={400}
                    value={value}
                    allowClear={true}
                    onChange={(dates) => {
                        const [start, end] = dates as [Dayjs, Dayjs];
                        setValue([start.startOf('day'), end.endOf('day')]);
                        onChange([start.startOf('day'), end.endOf('day')]);
                    }}
                    onCalendarChange={(dates, _, info) => {
                        if(info.range === 'start') {
                            setSelectedDates([dates[0], selectedDates[1]]);
                        } else if(info.range === 'end') {
                            setSelectedDates([selectedDates[0], dates[1]]);
                        }
                    }}
                    onOpenChange={handleOpenChange}
                    format="DD/MM/YYYY"
                    disabledDate={handleDisabledDate}
                />
            </ConfigProvider>
        </FlexBox>
    );
};