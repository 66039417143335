
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';

export interface CreateAccountRequest extends HttpRequestData {
    readonly body: {
        readonly name: string;
        readonly email: string;
        readonly password?: string;
        readonly salonIds?: number[];
        readonly phoneNumber?: string;
    };
}

export interface CreateAccountResponse {
    readonly id?: string;
    readonly email?: string;
    readonly name?: string;
}

export const createAccount = httpUtil.createHttpRequestMeta<
    CreateAccountRequest,
    CreateAccountResponse
>({
    baseUrl: env.orgServer,
    path: '/accounts',
    method: 'POST',
    authentication: 'bearer'
});
