import { env } from '@env';
import { httpUtil } from '@/framework';
import { GetCustomerBarChartResponse, GetCustomerChartRequest } from './Types';

export const getCustomerSourceChart = httpUtil.createHttpRequestMeta<
    GetCustomerChartRequest,
    GetCustomerBarChartResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/source-chart',
    method: 'GET',
    authentication: 'bearer'
});
