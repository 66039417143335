import { Salon } from '@/api';
import { atom } from 'recoil';

export interface AccountInfo {
    readonly id: string;
    readonly email?: string;
    readonly rootAccount?: boolean;
    readonly salons: Salon[];
}

export const accountInfoState = atom<AccountInfo | null>({
    key: 'ACCOUNT_INFO',
    default: null
});

export const salonActiveState = atom<Salon | null>({
    key: 'SALON_ACTIVE',
    default: null
});

export const salonsState = atom<Salon[] | null>({
    key: 'SALONS',
    default: null
});
