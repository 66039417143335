import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';

export interface GetCustomerGenderChartRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: string;
        readonly salonBranchId?: string;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export type Sex = 'MALE' | 'FEMALE' | 'OTHER';

interface CustomerTotalBySex { sex: Sex, total: number }

export type GetCustomerGenderChartResponse = CustomerTotalBySex[] 

export const getCustomerGenderChart = httpUtil.createHttpRequestMeta<
    GetCustomerGenderChartRequest,
    GetCustomerGenderChartResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/gender-chart',
    method: 'GET',
    authentication: 'bearer'
});
