import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { StatusBookingDetailData, StatusBookingDetailTable } from "./table-detail/StatusBookingDetailTable";
import { useQueriesBookingSummaryStatus } from "../hooks";
import { Dayjs } from "dayjs";
import { useHttpQueries } from "@/hooks";
import { useCallback, useMemo } from "react";
import { GetBookingSummaryStatusResponse } from "@/api";

export interface StatusBookingData {
    readonly id?: string;
    readonly title?: string;
    readonly value?: string | number;
    readonly dataDetails?: StatusBookingDetailData[];
}

interface StatusBookingTableProps {
    dateRange: [Dayjs, Dayjs];
}

export function StatusBookingTable({ dateRange }: StatusBookingTableProps) {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);

    const queriesBookingSummaryStatus = useQueriesBookingSummaryStatus(dateRange);

    const queriesBookingSummaryStatusResult = useHttpQueries(queriesBookingSummaryStatus);
    const bookingSummaryStatusData = useMemo(() => 
        queriesBookingSummaryStatusResult.map((query) => query.data) as GetBookingSummaryStatusResponse[], 
    [queriesBookingSummaryStatusResult]);

    const isLoading = useMemo(() => {
        return queriesBookingSummaryStatusResult.some((query) => query.isLoading);
    }, [queriesBookingSummaryStatusResult]);

    const showDetail = useCallback((title: string, dataDetail: StatusBookingDetailData[]) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <StatusBookingDetailTable dataDetails={dataDetail} />
        });
    }, [globalModal, setGlobalModal]);

    const getBookingSummaryStatusDetail = useCallback((field: keyof GetBookingSummaryStatusResponse) => {
        return bookingSummaryStatusData.map((item) => {
            const nameSalonBranch = salonActive?.salonBranches?.find((salonBranch) => salonBranch.id === item?.salonBranchId)?.name;
            return {
                value: item?.[field],
                title: nameSalonBranch,
            }
        }) as StatusBookingDetailData[];
    }, [bookingSummaryStatusData, salonActive]);

    const data: StatusBookingData[] = useMemo(() => [
        {
            value: bookingSummaryStatusData.reduce((acc, curr) => acc + (curr?.totalBookingNew ?? 0), 0),
            title: "Mới",
            dataDetails: getBookingSummaryStatusDetail('totalBookingNew'),
        },
        {
            value: bookingSummaryStatusData.reduce((acc, curr) => acc + (curr?.totalBookingConfirm ?? 0), 0),
            title: "Xác nhận",
            dataDetails: getBookingSummaryStatusDetail('totalBookingConfirm'),
        },
        {
            value: bookingSummaryStatusData.reduce((acc, curr) => acc + (curr?.totalBookingCheckIn ?? 0), 0),
            title: "Check-In",
            dataDetails: getBookingSummaryStatusDetail('totalBookingCheckIn'),
        },
        {
            value: bookingSummaryStatusData.reduce((acc, curr) => acc + (curr?.totalBookingCheckOut ?? 0), 0),
            title: "Check-Out",
            dataDetails: getBookingSummaryStatusDetail('totalBookingCheckOut'),
        },
        {
            value: bookingSummaryStatusData.reduce((acc, curr) => acc + (curr?.totalBookingNotCome ?? 0), 0),
            title: "Không đến",
            dataDetails: getBookingSummaryStatusDetail('totalBookingNotCome'),
        },
        {
            value: bookingSummaryStatusData.reduce((acc, curr) => acc + (curr?.totalBookingCancel ?? 0), 0),
            title: "Huỷ",
            dataDetails: getBookingSummaryStatusDetail('totalBookingCancel'),
        }
    ], [bookingSummaryStatusData, getBookingSummaryStatusDetail]);

    const columns: TableColumnsType<StatusBookingData> = useMemo(() => [
        {
            title: 'Trạng thái',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Số lượng',
            dataIndex: 'value',
            key: 'value',
            render: (value: number) => value?.toLocaleString('en-US'),
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <TagViewDetailColumn showDetail={() => showDetail(record.title ?? '', record.dataDetails ?? [])} />
                );
            },
        },
    ], [showDetail]);

    return (
        <TableCustom
            loading={isLoading}
            scroll={{ x: 800 }}
            showHeader={false}
            columns={columns}
            dataSource={data}
        />
    )
}
