import { Text } from '@components/typo';
import { Avatar, Card } from 'antd';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Block, FlexBox } from '@components/layouts';
import { IconRevenue } from '@components/icons';
import { UseQueryResult } from '@tanstack/react-query';
import { GetRevenueOverviewResponse } from '@/api';
import { revenueReportUtils } from './utils';

interface ReportRevenueOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly revenueOverview: UseQueryResult<GetRevenueOverviewResponse, Error>[]
}
export default function ReportRevenueOverview(props: ReportRevenueOverviewProps) {
    const { revenueOverview } = props;
    const isLoading = revenueOverview.some(query => query.isLoading);
    const revenueOverviews = useMemo(() => [
        {
            label: "Tổng doanh thu",
            value: revenueReportUtils.getTotalRevenue(revenueOverview, "ALL").totalRevenue,
            color: '#EFF4FF',
            icon: <IconRevenue />
        },
        {
            label: "Dịch vụ",
            value: revenueReportUtils.getTotalRevenue(revenueOverview, "SERVICE").totalRevenue,
            color: '#F6FFED',
            icon: <IconRevenue color='#17B26A' />
        },
        {
            label: "Sản phẩm",
            value: revenueReportUtils.getTotalRevenue(revenueOverview, "PRODUCT").totalRevenue,
            color: '#FFF7E6',
            icon: <IconRevenue color='#D48806' />
        },
        {
            label: "Gói dịch vụ",
            value: revenueReportUtils.getTotalRevenue(revenueOverview, "PACKAGE").totalRevenue,
            color: '#FFF1F0',
            icon: <IconRevenue color='#F04438' />
        }
    ], [revenueOverview]);

    return (
        <FlexBox className='overview-cards'>
            {revenueOverviews.map((o, i) => (
                <Card key={i} className='card-overview-revenue' loading={isLoading}>
                    <FlexBox gap={8} alignItems='center' flex='none'>
                        <Avatar
                            style={{ backgroundColor: o.color }}
                            size={42}
                            icon={o.icon}
                        />
                        <FlexBox direction='column'>
                            <Text color='#8F9BB2' maxLines={1} maxWidth={200} fontWeight={500} >{o.label}</Text>
                            <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                <Block>
                                    <Text color='#0A1F44' fontWeight={500} fontSize={20}>
                                        {o.value.toLocaleString('en-US')}
                                    </Text>
                                </Block>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Card>
            ))}
        </FlexBox>
    );
}
