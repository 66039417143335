import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";
import { getRevenueOfCategoryDetail, GetRevenueOfCategoryDetailResponse } from "@/api";
import { useMemo } from "react";
import { formatDateTime } from "@/utils";

interface RevenueByTypeCategoryDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
    readonly categoryId: number;
}

export function RevenueByTypeCategoryDetailTable(props: RevenueByTypeCategoryDetailTableProps) {
    const [salonActive] = useRecoilState(salonActiveState);

    const {data : revenueOfCategoryDetail, isFetching} = useHttpQuery(getRevenueOfCategoryDetail, {
        query: {
            startDate: formatDateTime(props.dateRange[0]),
            endDate: formatDateTime(props.dateRange[1]),
            objectType: props.objectType,
            categoryId: props.categoryId,
            salonId: salonActive?.id ?? 0
        }
    });

    const columns = useMemo<TableColumnsType<GetRevenueOfCategoryDetailResponse >>(() => 
			[
				{
					title: 'Chi nhánh',
					dataIndex: 'salonBranchId',
					key: 'name',
					render: (salonBranchId: number) =>
						salonActive?.salonBranches?.find((branch) => branch.id === salonBranchId)
							?.name,
				},
				{
					title: 'Số lượng',
					dataIndex: 'totalQuantity',
					key: 'totalQuantity',
					width: 250,
				},
				{
					title: 'Doanh thu',
					dataIndex: 'totalRevenue',
					render: (value: number) => value.toLocaleString('en-US'),
					key: 'totalRevenue',
					width: 150,
				},
			],[salonActive?.salonBranches]);

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={revenueOfCategoryDetail?.items}
                showFooter={false}
                loading={isFetching}
            />
        </div>
    )
}
