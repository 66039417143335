import { GetBookingOverviewChartResponseItem, GetBookingOverviewResponse } from "@/api/report/booking";

export const bookingReportUtils = {
    getTotalBookingOverview: (bookingOverview: (GetBookingOverviewResponse | undefined)[], field: keyof GetBookingOverviewResponse) => {
        return bookingOverview.reduce((total, current) => {
            return total + (current?.[field] ?? 0);
        }, 0);
    },
    getTotalBookingOverviewChart: (bookingOverviewChart: (GetBookingOverviewChartResponseItem | undefined)[], field: keyof GetBookingOverviewChartResponseItem) => {
        return bookingOverviewChart.reduce((total, current) => {

            if (typeof current?.[field as keyof typeof current] === 'number') {
                
                return total + (current[field as keyof typeof current] as number);
            }

            return total;
        }, 0);
    }
}
