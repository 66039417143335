
import { Block, FlexBox } from '@/components/layouts';
import { BankTransferIcon, CashIcon, CreditCardIcon, MembershipCreditIcon, OtherPaymentIcon, PrepaidCashIcon, SplitPaymentIcon } from '@/components/icons';

interface PaymentMethodImageProps {
    readonly paymentMethodCode: string;
}

export const PaymentMethodColumn = (props: PaymentMethodImageProps) => {
    const { paymentMethodCode } = props;
    const paymentMethod = PaymentMethodColumn.getPaymentMethod(paymentMethodCode);

    return (
        <FlexBox alignItems='center'>
            <Block display='flex' width={44} alignItems='center' >
                {paymentMethod?.icon}
            </Block>
            {paymentMethod?.name}
        </FlexBox>
    );
};

PaymentMethodColumn.getPaymentMethod = (paymentMethodCode: string) => {
    switch (paymentMethodCode) {
        case "CASH_CARD":
            return {
                icon: <CreditCardIcon />,
                name: "Thẻ tiền mặt"
            }
        case "CASH":
            return {
                icon: <CashIcon />,
                name: "Tiền mặt"
            }
        case "BANK_TRANSFER":
            return {
                icon: <BankTransferIcon />,
                name: "Chuyển khoản"
            }
        case "SWIPE_CARD":
            return {
                icon: <SplitPaymentIcon />,
                name: "Quẹt thẻ"
            }
        case "DEBIT":
            return {
                icon: <PrepaidCashIcon />,
                name: "Ghi nợ"
            }
        case "LOYALTY":
            return {
                icon: <MembershipCreditIcon />,
                name: "Điểm tích luỹ"
            }
        default:
            return {
                icon: <OtherPaymentIcon />,
                name: "Khác"
            }
    }
};
