import { GetRevenueOverviewResponse } from "@/api";
import { UseQueryResult } from "@tanstack/react-query";

export const revenueReportUtils = {
    getTotalRevenue: (revenueOverview: UseQueryResult<GetRevenueOverviewResponse, Error>[], objectType: string) => {
        return revenueOverview?.reduce((acc, cur) => {
            if( cur.data?.objectType === objectType){
                return {
                    totalRevenue: acc.totalRevenue + (cur?.data?.totalRevenue ??0 ),
                    totalInvoice: acc.totalInvoice + (cur?.data?.totalInvoice ??0 )
                }
            }
    
            return acc;
        }, {totalRevenue: 0, totalInvoice: 0});
    }
}
