import { Tabs } from 'antd';
import { Dayjs } from 'dayjs';
import { FlexBox, Spacer } from '@components/layouts';
import { OverViewRevenueTable } from './table/OverviewRevenueTable';
import {  RevenueByTypeCategoryTable } from './table/RevenueByTypeCategoryTable';
import { RevenueByObjectTypeTable } from './table/RevenueByObjectTypeTable';
import { RevenueByPaymentMethodTable } from './table/RevenueByPaymentMethodTable';
import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { salonActiveState } from '@/components/state';
import { useRecoilState } from 'recoil';
import { RevenueByStaffTable } from './table/RevenueByStaffTable';
import { GetRevenueOverviewResponse } from '@/api';

interface ReportRevenueTabsProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly revenueOverview: UseQueryResult<GetRevenueOverviewResponse, Error>[]
}

export default function ReportRevenueTabs(props: ReportRevenueTabsProps) {
    const { dateRange, revenueOverview } = props;
    const [salonActive] = useRecoilState(salonActiveState);

    const tabsContent = useMemo(() => {
        let  items = [
            {
                key: '1',
                label: "Tổng quan",
                children: <OverViewRevenueTable revenueOverview={revenueOverview}/>,
            },
        ]

        if(salonActive) {
            items = [...items, {
                key: '2',
                label: 'Sản phẩm',
                children: (
                    <FlexBox gap={24} direction='column'>
                        <RevenueByTypeCategoryTable dateRange={dateRange} objectType='PRODUCT'/>
                        <RevenueByObjectTypeTable dateRange={dateRange} objectType='PRODUCT'/>
                        <RevenueByStaffTable dateRange={dateRange} objectType='PRODUCT' />
                    </FlexBox>
                ),
            },
            {
                key: '3',
                label: 'Dịch vụ',
                children: (
                    <FlexBox gap={24} direction='column'>
                        <RevenueByTypeCategoryTable dateRange={dateRange} objectType='SERVICE'/>
                        <RevenueByObjectTypeTable dateRange={dateRange}  objectType='SERVICE'/>
                        <RevenueByStaffTable dateRange={dateRange} objectType='SERVICE' />
                    </FlexBox>
                ),
            },
            {
                key: '4',
                label: 'Phương thức thanh toán',
                children: <RevenueByPaymentMethodTable dateRange={dateRange} />,
            }]
        }

        return items;
        
    }, [revenueOverview, dateRange, salonActive]);

    return (
        <Spacer top={24} >
            <Tabs defaultActiveKey='1' items={tabsContent} />
        </Spacer>
    );
}
