import { useSearchParams } from "@/hooks";

interface PaginationCategory {
    readonly categorySearch: string;
    readonly categoryPage: string;
    readonly categoryPageSize: string;
    readonly categoryOrderDirection?: string;
    readonly categoryByOrderBy?: string;
}


export function usePaginationCategory() {
    const keys: (keyof PaginationCategory)[] = ['categorySearch', 'categoryPage', 'categoryPageSize', 'categoryOrderDirection', 'categoryByOrderBy'];
    const initPagination: PaginationCategory = {
        categorySearch: '',
        categoryPage: '1',
        categoryPageSize: '20',
    }

    return useSearchParams(initPagination, keys);
}
