import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";
import { useMemo } from "react";

export interface OverviewBookingDetailData {
    readonly id?: string;
    readonly name?: string;
    readonly value?: number| string;
}

interface OverviewBookingDetailTableProps {
    readonly dataTable: OverviewBookingDetailData[];
}


export function OverviewBookingDetailTable(props: OverviewBookingDetailTableProps) {
    const { dataTable } = props;
    const [salonActive] = useRecoilState(salonActiveState);

    const columns: TableColumnsType<OverviewBookingDetailData> = useMemo(() => [
        {
            key: 'title',
            title: salonActive ? 'Chi nhánh' : 'Salon',
            dataIndex: 'title',
        },
        {
            title: 'Giá trị',
            dataIndex: 'value',
            render: (value) => value?.toLocaleString('en-US'),
            key: 'value',
            width: 150
        }
    ], [salonActive]);

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={dataTable}
                showFooter={false}
            />
        </div>
    )
}
