import './TableCustom.scss';
import {
    Pagination,
    Table,
    TableColumnsType,
    TableProps
} from 'antd';
import React from 'react';

import { Block } from '../layouts';
import { HeaderTable } from './HeaderTable';
import { PageInfo } from '../../framework';
import { defaultPageInfo } from '../../shared/const/defaultPageInfo';
import { SorterResult } from 'antd/es/table/interface';

interface TableCustomProps<TItem> extends TableProps<TItem> {
    readonly placeholderSearchTransCode?: string;
    readonly titleTableTransCode?: string;
    readonly subTitleTable?: React.ReactNode;
    readonly onPageChange?: (nextPage: number) => void;
    readonly pageInfo?: PageInfo;
    readonly showHeader?: boolean;
    readonly showFooter?: boolean;
    readonly onSearch?: (value: string) => void;
    readonly onFilter?: () => void;
    readonly onCreate?: () => void
    readonly btnCreateText?: string;
    readonly dataSource?: TItem[];
    readonly columns?: TableColumnsType<TItem>;
    readonly disableFilter?: boolean;
    readonly onExportExcel?: () => void;
    readonly onSort?: (orderDirection: 'asc' | 'desc' | undefined, orderBy?: string) => void;
}


interface TableCustomItem {
}

type TableCustomTypeType = <TItem extends TableCustomItem>(
    props: TableCustomProps<TItem>
) => React.ReactElement<TableCustomProps<TItem>>;

export const TableCustom: TableCustomTypeType = (props) => {

    const renderHeader = React.useCallback(() => {
        return (
            <HeaderTable
                playHolderInputSearch={props?.placeholderSearchTransCode || ''}
                titleTable={props?.titleTableTransCode || ''}
                subTitleTable={props.subTitleTable}
                onSearch={props.onSearch}
                onFilter={props.onFilter}
                onCreate={props.onCreate}
                btnCreateText={props.btnCreateText}
                onExportExcel={props.onExportExcel}
            />
        );
    }, [props]);

    const renderFooter = React.useCallback(() => {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                    className='custom-pagination'
                    current={props?.pageInfo?.page}
                    total={props?.pageInfo?.totalItems}
                    pageSize={props.pageInfo?.pageSize}
                    onChange={(nextPage) => {
                        if (props.onPageChange) {
                            props.onPageChange(nextPage);
                        }
                    }}
                />
            </div>
        );
    }, [props]);

    const getSortOrder = (sorter: SorterResult<TableCustomItem> | SorterResult<TableCustomItem>[]) => {
        let order: 'asc' | 'desc' | undefined;
        let columnKey: string | undefined;

        if (Array.isArray(sorter)) {
            sorter = sorter[0]; // Take the first sorter if it's an array
        }

        if ('order' in sorter && sorter.order) {
            order = sorter.order === 'ascend' ? 'asc' : 'desc';
            columnKey = sorter.columnKey as string;
        }
        
        return {
            order,
            columnKey
        }
    };

    return (
        <Block borderRadius={16} backgroundColor='white'>
            <Table
                scroll={props.scroll}
                loading={props.loading}
                className='custom-table'
                title={props.showHeader ? renderHeader : undefined}
                columns={props.columns}
                sortDirections={['ascend', 'descend']}
                onChange={(_, __, sorter, ___) => {
                    if (props.onSort) {
                        const { order, columnKey } = getSortOrder(sorter as SorterResult<TableCustomItem>);
                        props.onSort(order, columnKey);
                    }
                    props.onChange?.(_, __, sorter, ___)
                }}
                dataSource={props.dataSource}
                pagination={false}
                footer={props.showFooter ? renderFooter : undefined}
            >
                {props.children}
            </Table>
        </Block>
    );
};

(TableCustom as React.ComponentType<TableCustomProps<TableCustomItem>>).defaultProps = {
    pageInfo: defaultPageInfo,
    showHeader: true,
    showFooter: true,
    placeholderSearchTransCode: 'MISSING_PLACEHOLDER_SEARCH',
    titleTableTransCode: '',
    scroll: { x: 'max-content' },
};


