import { SelectField } from '@components/inputs/fields/SelectField';
import { Block, FlexBox, Spacer } from '@components/layouts';
import { Header } from '@components/typo/Header';
import { useRecoilState } from 'recoil';
import { salonActiveState } from '@components/state';
import { FormBase } from '@components/base';
import { Dayjs } from 'dayjs';
import { ReportCustomerSubChart } from './children/report-customer-sub-chart';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { chartHelper } from '@/helpers';
import { ReportCustomerOverview } from './children/report-customer-overview';
import { CustomerTotalReportChart } from './children/customer-total-report-chart';
import { useOutletContext } from 'react-router-dom';

export default function CustomerReport() {
	const [salonActive] = useRecoilState(salonActiveState);
    const { dateRange } = useOutletContext<{ dateRange: [Dayjs, Dayjs] }>();

	const [selectedSalonBranchId, setSelectedSalonBranchId] = useState<number | undefined>(
		salonActive?.salonBranches?.[0].id
	);

	const isShowChart = useMemo(() => {
		const viewType = chartHelper.getViewTypeChart(dateRange);

		return typeof viewType !== 'undefined';
	}, [dateRange]);

	useEffect(() => {
		setSelectedSalonBranchId(undefined);
	}, [salonActive]);

	const salonBranchOptions = useMemo(() => {
		return (
			salonActive?.salonBranches?.map((i) => ({
				label: i?.name,
				value: i?.id,
			})) ?? []
		);
	}, [salonActive?.salonBranches]);

	const handleSalonBranchChange = useCallback((value: number | undefined) => {
		setSelectedSalonBranchId(value);
	}, []);

	return (
		<FormBase onSubmit={() => {}}>
			<Block backgroundColor='white' className='tab-content'>
				<FlexBox direction='column' gap={20}>
					<FlexBox alignItems='center'>
						<FlexBox>
							<Header level={3}>Báo cáo khách hàng</Header>
						</FlexBox>
						<Spacer right={400} />
						<FlexBox gap={20}>
							{salonActive && salonActive.isDivideCustomerByBranch === 'true' && (
								<SelectField
									size='large'
									label=''
									showSearch
									name='salon'
									allowClear
									options={salonBranchOptions}
									value={selectedSalonBranchId}
									placeholder='Select Salon branch'
									onChange={handleSalonBranchChange}
									defaultValue={selectedSalonBranchId}
								/>
							)}
						</FlexBox>
					</FlexBox>
					<ReportCustomerOverview
						salonBranchId={selectedSalonBranchId}
						dateRange={dateRange}
					/>
					{isShowChart && (
						<CustomerTotalReportChart
							salonBranchId={selectedSalonBranchId}
							dateRange={dateRange}
						/>
					)}
					<ReportCustomerSubChart
						salonBranchId={selectedSalonBranchId}
						dateRange={dateRange}
					/>
				</FlexBox>
			</Block>
		</FormBase>
	);
}
