import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { Dayjs } from "dayjs";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";
import { getReportBookingByStaffDetail, GetReportBookingByStaffDetailItem } from "@/api";
import { useHttpQuery } from "@/hooks";
import { useMemo } from "react";
import { formatDateTime } from "@/utils";

interface ServiceByStaffBookingDetailTableProps {
    readonly staffId: number;
    readonly dateRange: [Dayjs, Dayjs];
}

export function ServiceByStaffBookingDetailTable({ staffId, dateRange }: ServiceByStaffBookingDetailTableProps) {

    const [salonActive] = useRecoilState(salonActiveState);
    const { data: bookingByStaffDetailResponse, isLoading } = useHttpQuery(getReportBookingByStaffDetail, {
        query: {
            staffId,
            startDate: formatDateTime(dateRange[0]),
            endDate: formatDateTime(dateRange[1]),
            salonId: salonActive?.id,
        }
    })

    const columns: TableColumnsType<GetReportBookingByStaffDetailItem> = useMemo(() => [
        {
            key: 'serviceName',
            title: 'Dịch vụ',
            dataIndex: 'serviceName',
        },
        {
            key: 'totalQuantity',
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            render: (totalQuantity: number) => totalQuantity?.toLocaleString('en-US'),
            width: 250
        },
        {
            key: 'totalRevenueBooking',
            title: 'Doanh thu',
            dataIndex: 'totalRevenueBooking',
            render: (totalRevenueBooking: number) => totalRevenueBooking?.toLocaleString('en-US'),
            width: 150
        }
    ], []);

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                loading={isLoading}
                showHeader={false}
                columns={columns}
                dataSource={bookingByStaffDetailResponse?.items}
                pagination={bookingByStaffDetailResponse?.meta}
            />
        </div>
    )
}
