import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { Dayjs } from "dayjs";
import { getReportBookingByServiceDetail, GetReportBookingByServiceDetailItem } from "@/api";
import { useHttpQuery } from "@/hooks";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";
import { useMemo } from "react";
import { formatDateTime } from "@/utils";

interface ServiceByBookingDetailTableProps {
    readonly serviceId: number;
    readonly dateRange: [Dayjs, Dayjs];
}

export function ServiceByBookingDetailTable({ serviceId, dateRange }: ServiceByBookingDetailTableProps) {

    const [salonActive] = useRecoilState(salonActiveState);
    const { data: bookingByServiceDetailResponse, isLoading } = useHttpQuery(getReportBookingByServiceDetail, {
        query: {
            serviceId,
            startDate: formatDateTime(dateRange[0]),
            endDate: formatDateTime(dateRange[1]),
            salonId: salonActive?.id,
        }
    })

    const columns: TableColumnsType<GetReportBookingByServiceDetailItem> = useMemo(() => [
        {
            key: 'salonBranchId',
            title: 'Chi nhánh',
            dataIndex: 'salonBranchId',
            render: (salonBranchId: number) =>salonActive?.salonBranches?.find((branch) => branch.id === salonBranchId)?.name,
        },
        {
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            render: (totalQuantity: number) => totalQuantity?.toLocaleString('en-US'),
            width: 150
        },
        {
            title: 'Doanh thu',
            dataIndex: 'totalRevenueBooking',
            key: 'totalRevenueBooking',
            render: (totalRevenueBooking: number) => totalRevenueBooking?.toLocaleString('en-US'),
            width: 150
        }
    ], [salonActive]);

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={bookingByServiceDetailResponse?.items}
                pageInfo={bookingByServiceDetailResponse?.meta}
                loading={isLoading}
            />
        </div>
    )
}
