
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { ViewType } from '@/shared/enum/chartEnum';

export interface GetBookingOverviewChartRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly viewType?: ViewType;
    }
}
export interface GetBookingOverviewChartResponseItem {
    readonly dateTimeGroup: string;
    readonly totalBooking: number;
    readonly totalBookingCancel: number;
    readonly totalBookingOnline: number;
    readonly totalBookingSalon: number;
    readonly totalRevenueBooking: number;
    readonly salonId: number;
    readonly salonBranchId?: number;
}

export const getBookingOverviewChart = httpUtil.createHttpRequestMeta<
    GetBookingOverviewChartRequest,
    GetBookingOverviewChartResponseItem[]
>({
    baseUrl: env.orgServer,
    path: '/bookings/chart-overview',
    method: 'GET',
    authentication: 'bearer'
});
