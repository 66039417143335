import { Line } from '@ant-design/plots';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { chartHelper } from '@/helpers';
import { ItemChart } from '../ReportRevenueChart';
import { GetRevenueOverviewChartResponseItem } from '@/api';

interface RevenueLineChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly revenueData: (GetRevenueOverviewChartResponseItem[] | undefined)[];
    readonly isLoading: boolean;
}

export default function RevenueLineChart(props: RevenueLineChartProps) {
    const { dateRange, revenueData, isLoading } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);
    const { watch } = useFormContext();
    const numberYearQuery = watch('numberYear');

    const revenueOverviewChartGroupByYear = useMemo(() => {
        const numberYear = numberYearQuery ?? 1;

        const dataEachYear = new Array(numberYear).fill(0)?.map((_, index) => {
            const startDate = dayjs().subtract(index, 'year').startOf('year');

            const revenueOfYear = revenueData.filter((item) => dayjs(item?.[0]?.date).isSame(startDate, 'year'));

            const groupData = revenueOfYear?.[0]?.map((item, index) => {
                const totalRevenue = revenueOfYear?.reduce((acc, curr) => {
                    return acc + (curr?.[index]?.totalRevenue ?? 0);
                }, 0);

                return {
                    date: item.date,
                    totalRevenue,
                }
            });

            return groupData;

        });

        return dataEachYear;
    }, [numberYearQuery, revenueData]);

    const dataLineChart = useMemo(() => revenueOverviewChartGroupByYear?.map((dataEachYear) => {
        return dataEachYear?.map((item) => {
            return {
                date: numberYearQuery? `Tháng ${dayjs(item.date).month() + 1}`: chartHelper.getLabelDateByViewChart(viewType, item.date),
                value: item.totalRevenue,
                type: `năm ${dayjs(item.date).year()}`
            }
        })
    }).flat().filter(o=> !!o) as ItemChart[], [numberYearQuery, revenueOverviewChartGroupByYear, viewType]);

    const lineChartConfig = useMemo(() => ({
        data: dataLineChart,
        xField: 'date',
        yField: 'value',
        colorField: 'type',
        scrollbar: { x: { ratio: 0.7 } },
        axis: {
            y: {
                labelFormatter: (v: unknown) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        interaction: {
            tooltip: {
                render: (_: React.ReactNode, { title, items }: { title: string, items:  Array<{ value: number, color: string, name: string }> }) => {

                    console.log("items11", items);
                    return (
                        `<div style="padding: 10px 20px; width: 300px; ">
                            <div style="margin-bottom: 10px">
                                <span style="color: #000">${title}</span>
                            </div>
                            <div style="margin-bottom: 8px">
                                <ul style="margin-left: 0px">
                                    ${items.map((item) => {
                                        return `
                                            <li style="color: ${item.color}">
                                                <div style="display: flex; justify-content: space-between">
                                                    <span style="color: #000; font-weight: 500">${item.name}: </span>
                                                    <span style="color: #000">${item.value.toLocaleString('en-US')}</span>
                                                </div>
                                            </li>`;
                                    }).join('')}
                                </ul>
                            </div>
                        </div>`
                    );
                },
            },
        },
        scale: { color: { range: ['#F04438', '#17B26A', '#2970FF'] } },
        style: {
            lineWidth: 2,
        },
    }), [dataLineChart]);

    return (
        <Line loading={isLoading} {...lineChartConfig} />
    );
}
