import { TableColumnsType } from "antd";
import { TableCustom, TagViewDetailColumn } from "@components/table";
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
import { RevenueByTypeCategoryDetailTable } from "./table-details/RevenueByTypeCategoryDetailTable";
import { useCallback, useMemo } from "react";
import { getRevenueOfCategoryTable, GetRevenueOfCategoryTableResponse } from "@/api";
import { usePaginationCategory } from "../hooks/usePaginationCategory";
import { formatDateTime } from "@/utils";

interface RevenueByTypeCategoryTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
}

export function RevenueByTypeCategoryTable(props: RevenueByTypeCategoryTableProps) {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);
    const [pagination, setPagination] = usePaginationCategory();

    const showDetail = useCallback((title?: string, categoryId?: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: (
                <RevenueByTypeCategoryDetailTable 
                    dateRange={props.dateRange} 
                    categoryId={categoryId!} 
                    objectType={props.objectType}
                />
            )
        });
    }, [globalModal, props.dateRange, props.objectType, setGlobalModal]);

    const {data : revenueOfCategoryTable, isFetching} = useHttpQuery(getRevenueOfCategoryTable, {
        query: {
            startDate: formatDateTime(props.dateRange[0]),
            endDate: formatDateTime(props.dateRange[1]),
            objectType: props.objectType,
            salonId: salonActive?.id ?? 0,
            useCountTotal: true,
            search: pagination.categorySearch,
            page: pagination.categoryPage,
            pageSize: pagination.categoryPageSize,
            orderDirection: pagination.categoryOrderDirection,
            orderBy: pagination.categoryByOrderBy
        }
    });

    const columns: TableColumnsType<GetRevenueOfCategoryTableResponse> = useMemo(()=> [
        {
            title: 'Tên danh mục',
            dataIndex: 'categoryName',
            key: 'name',
        },
        {
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
            width: 300,
            sorter: true,
            render: (value: number) => value.toLocaleString('en-US')
        },
        {
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            width: 150,
            sorter: true,
            render: (value: number) => value.toLocaleString('en-US')
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <TagViewDetailColumn showDetail={()=> showDetail(record.categoryName, record.categoryId)} />
                );
            },
        },
    ], [showDetail]);

    const objectTypeTransCode = useMemo(() =>
		props.objectType === 'SERVICE' ? 'dịch vụ' : 'sản phẩm'
	,[props.objectType]);

    return (
        <TableCustom
            loading={isFetching}
            scroll={{ x: 800 }}
            placeholderSearchTransCode='Tìm danh mục'
            onSearch={(value) => {
                setPagination({
                    ...pagination,
                    categorySearch: value
                });
            }}
            onPageChange={(nextPage) => {
                setPagination({
                    ...pagination,
                    categoryPage: nextPage.toString()
                });
            }}
            onSort={(orderDirection, orderBy) => {
                setPagination({
                    ...pagination,
                    categoryOrderDirection: orderDirection,
                    categoryByOrderBy: orderBy
                });
            }}
            pageInfo={revenueOfCategoryTable?.meta}
            titleTableTransCode={`Doanh thu theo danh mục ${objectTypeTransCode}`}
            showHeader={true}
            columns={columns}
            dataSource={revenueOfCategoryTable?.items}
        />
    )
}
