import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
import { getRevenueOfStaffDetail, GetRevenueOfStaffDetailResponse } from "@/api";
import { useMemo } from "react";
import { formatDateTime } from "@/utils";

interface RevenueByStaffDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
    readonly staffId: number;
}

export function RevenueByStaffDetailTable(props: RevenueByStaffDetailTableProps) {
    const [salonActive] = useRecoilState(salonActiveState);

    const {data : revenueOfStaffDetail, isFetching} = useHttpQuery(getRevenueOfStaffDetail, {
        query: {
            startDate: formatDateTime(props.dateRange[0]),
            endDate: formatDateTime(props.dateRange[1]),
            objectType: props.objectType,
            staffId: props.staffId,
            salonId: salonActive?.id ?? 0
        }
    });

    const columns = useMemo<TableColumnsType<GetRevenueOfStaffDetailResponse>>(
		() => [
			{
				key: 'name',
				title: 'Chi nhánh',
				dataIndex: 'salonBranchId',
				render: (salonBranchId: number) =>
					salonActive?.salonBranches?.find((branch) => branch.id === salonBranchId)?.name,
			},
			{
				title: 'Số lượng',
				dataIndex: 'totalQuantity',
				key: 'totalQuantity',
				width: 250,
			},
			{
				title: 'Doanh thu',
				dataIndex: 'totalRevenue',
				render: (value: number) => value.toLocaleString('en-US'),
				key: 'totalRevenue',
				width: 150,
			},
		],
		[salonActive?.salonBranches]
	);

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                loading={isFetching}
                showHeader={false}
                columns={columns}
                dataSource={revenueOfStaffDetail?.items}
                showFooter={false}
            />
        </div>
    )
}
