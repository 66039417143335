import { GetCustomerGenderChartResponse } from '@/api';
import { UseQueryResult } from '@tanstack/react-query';
import { groupBy, map } from 'lodash';

export const getCustomerGenderChartResource = (
	queyResults: UseQueryResult<GetCustomerGenderChartResponse, Error>[]
) => {
	const dataChart: {
		type: string;
		value: number;
	}[] = [];

	const dataGroup = groupBy(
		queyResults.flatMap((o) => o.data),
		(item) => item?.sex
	);

	map(dataGroup, (items, name) => {
		const total = items.reduce((s, item) => (s += item?.total ?? 0), 0);

		const index = name === 'MALE' ? 0 : name === 'FEMALE' ? 1 : 2;

		dataChart[index] = {
			type: getGender(name),
			value: total,
		};
	});

	const total = dataChart.reduce((s, item) => (s += item.value), 0);

	return {
		dataChart,
		total,
	};
};

export const getGender = (sex: string) => {
	switch (sex) {
		case 'FEMALE':
			return 'Nữ';
		case 'MALE':
			return 'Nam';
		default:
			return 'Khác';
	}
};

export const getCustomerTotalByGender = (
	queyResults: UseQueryResult<GetCustomerGenderChartResponse, Error>[]
) => {
	const dataGroup = groupBy(
		queyResults.flatMap((o) => o.data),
		(item) => item?.sex
	);

	const result: {
		gender: string;
		value: number
	}[] = []

	map(dataGroup, (items, name) => {
		const total = items.reduce((s, item) => (s += item?.total ?? 0), 0);
		result.push({
			gender: getGender(name),
			value: total
		})
	});

	return result
};
