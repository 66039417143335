import { TimeRangePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
dayjs.locale('vi')

export const dateHelper = {
    getStartAndEndOfWeek: () => {
        const currentDate = dayjs();

        const startOfWeek = currentDate.clone().startOf('week').add(1, 'day').endOf('day');
        const endOfWeek = currentDate.clone().endOf('week').add(1, 'day').endOf('day');

        return {
            startOfWeek: startOfWeek,
            endOfWeek: endOfWeek,
        };
    },
    getLabelPresets: (rangePresets: TimeRangePickerProps['presets'], dateRange: [Dayjs, Dayjs]) => {
        const foundPreset = rangePresets?.find((preset) => {
            const [start, end] = preset.value as [Dayjs, Dayjs];

            return start.isSame(dateRange[0]) && end.isSame(dateRange[1]);
        });

        return foundPreset?.label;
    },
    getStartAndEndOfMonth: (date?: string) => {
        const currentDate = date ? dayjs(date) : dayjs();
        const startOfMonth = currentDate.clone().startOf('month');
        const endOfMonth = currentDate.clone().endOf('month');

        return {
            startOfMonth: startOfMonth,
            endOfMonth: endOfMonth,
        };
    },
    checkIsRangeDateOfMonth: (startDate: Dayjs, endDate: Dayjs) => {
        const startOfMonth = startDate.startOf('month');
        const endOfMonth = startDate.endOf('month');

        return startOfMonth.isSame(startDate) && endOfMonth.isSame(endDate);
    },
    getDateRangePrevious: (startDate: Dayjs, endDate: Dayjs) => {
        const numberDaysRange = endDate.diff(startDate, 'd');

        const isViewByOneDay = numberDaysRange === 0;
        const isViewByMonth = dateHelper.checkIsRangeDateOfMonth(startDate, endDate);

        const dateBefore = startDate.add(-1, 'd');

        if (isViewByOneDay) {
            return [dateBefore, endDate.add(-1, 'd')];
        }

        if (isViewByMonth) {
            return [startDate.add(-1, 'month'), dateBefore.endOf('d')];
        }

        return [startDate.add(-numberDaysRange, 'd'), dateBefore.endOf('d')];
    },
    getDateRangeFromQueryParams: () => {
        const currentDate = dayjs();
        const startOfMonth = currentDate.clone().startOf('month');
        const endOfMonth = currentDate.clone().endOf('month');

        const queryParams = new URLSearchParams(window.location.search);
        const dateRange = queryParams.get('dateRange');

        if (dateRange) {
            return dateRange?.split(',').map(dayjs) as [Dayjs, Dayjs];
        }

        return [startOfMonth, endOfMonth] as [Dayjs, Dayjs];
    }
};