import { getBookingOverview } from "@/api/report/booking";
import { accountInfoState, salonActiveState } from "@/components/state";
import { dateHelper } from "@/helpers";
import { formatDateTime } from "@/utils";
import { Dayjs } from "dayjs";
import { useMemo } from "react";
import { useRecoilState } from "recoil";

export function useQueriesBookingOverviews(dateRange: [Dayjs, Dayjs]) {

    const [salonActive] = useRecoilState(salonActiveState);
    const [currentAccount] = useRecoilState(accountInfoState);

    const previousDateRange = dateHelper.getDateRangePrevious(dateRange[0], dateRange[1]);

    const currentQueries = useMemo(() => {
        if(salonActive){
            const queriesOverviewSalonActive = salonActive.salonBranches?.map((branch) => {
                return {
                    requestMeta: getBookingOverview,
                    requestData: {
                        query: {
                            startDate: formatDateTime(dateRange[0]),
                            endDate: formatDateTime(dateRange[1]),
                            salonId: salonActive.id,
                            salonBranchId: branch.id
                        }
                    }
                }
            }).flat() ?? [];

            return queriesOverviewSalonActive;
        }

        const queriesOverviewAllSalons = currentAccount?.salons?.map((salon) => {
            return {
                requestMeta: getBookingOverview,
                requestData: {
                    query: {
                        startDate: formatDateTime(dateRange[0]),
                        endDate: formatDateTime(dateRange[1]),
                        salonId: salon.id
                    }
                }
            }
        }).flat() ?? [];

        return queriesOverviewAllSalons;
    }, [salonActive, currentAccount, dateRange]);

    const previousQueries = useMemo(() => {
        return currentQueries.map((query) => {
            return {
                ...query,
                requestData: {
                    ...query.requestData,
                    query: {
                        ...query.requestData.query,
                        startDate: formatDateTime(previousDateRange[0]),
                        endDate: formatDateTime(previousDateRange[1]),
                    }
                }
            }
        });
    }, [currentQueries, previousDateRange]);

    return {
        currentQueries,
        previousQueries
    }
}
