import { useSearchParams } from "@/hooks";

interface PaginationBookingStaff {
    readonly staffSearch: string;
    readonly staffPage: string;
    readonly staffPageSize: string;
    readonly staffOrderDirection?: string;
    readonly staffOrderBy?: string;
}

export function usePaginationBookingStaff() {
    const initPagination: PaginationBookingStaff = {
        staffSearch: '',
        staffPage: '1',
        staffPageSize: '20',
        staffOrderDirection: '',
        staffOrderBy:''
    }

    return useSearchParams<PaginationBookingStaff>(initPagination, [
        'staffOrderBy',
        'staffOrderDirection',
        'staffPage',
        'staffPageSize',
        'staffSearch'
    ]);
}