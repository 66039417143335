import { Text } from '@components/typo';
import { Avatar, Card } from 'antd';
import { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';
import { Block, FlexBox } from '@components/layouts';
import {  IconBookingCancel, IconBookingOnline, IconGrowDown, IconGrowUp, IconRevenue, IconSalon, IconTotalBooking } from '@components/icons';
import { useHttpQueries } from '@/hooks';
import { useQueriesBookingOverviews } from './hooks/useQueriesBookingOverviews';
import { bookingReportUtils } from './utils';


interface BookingReportOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
}
export default function BookingReportOverview(props: BookingReportOverviewProps) {
    const { dateRange } = props;

    const { currentQueries, previousQueries } = useQueriesBookingOverviews(dateRange);

    const bookingOverview = useHttpQueries(currentQueries);
    const previousBookingOverview = useHttpQueries(previousQueries);

    const isLoading = bookingOverview.some((query) => query.isLoading) || previousBookingOverview.some((query) => query.isLoading);

    const bookingOverviewData = useMemo(()=> bookingOverview.map((query) => query.data), [bookingOverview]);
    const previousBookingOverviewData = useMemo(()=> previousBookingOverview.map((query) => query.data), [previousBookingOverview]);

    const renderGrow = useCallback((current: number, previous: number) => {
        const grow = current - previous;
        const color = grow >= 0 ? 'green' : 'red';

        if (previous === 0) {
            return <Text color={color}>( - )</Text>;
        }

        const percent = (grow / previous) * 100;

        return (
            <FlexBox alignItems='center' >
                <Text fontWeight={600} color={color}>({percent.toFixed(2)}%)</Text>
                {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
            </FlexBox>
        );

    },[])

    const bookingOverviews = useMemo(() => [
        {
            label: "Tổng số lịch hẹn",
            value: bookingReportUtils.getTotalBookingOverview(bookingOverviewData, 'totalBooking'),
            preValue: bookingReportUtils.getTotalBookingOverview(previousBookingOverviewData, 'totalBooking'),
            color: '#EFF4FF',
            icon: <IconTotalBooking />
        },
        {
            label: "Lịch hẹn đã huỷ",
            value: bookingReportUtils.getTotalBookingOverview(bookingOverviewData, 'totalBookingCancel'),
            preValue: bookingReportUtils.getTotalBookingOverview(previousBookingOverviewData, 'totalBookingCancel'),
            color: '#FEF3F2',
            icon: <IconBookingCancel />
        },
        {
            label: "Đặt lịch online",
            value: bookingReportUtils.getTotalBookingOverview(bookingOverviewData, 'totalBookingOnline'),
            preValue: bookingReportUtils.getTotalBookingOverview(previousBookingOverviewData, 'totalBookingOnline'),
            color: '#ECFDF3',
            icon: <IconBookingOnline />
        },
        {
            label: "Đặt lịch tại salon",
            value: bookingReportUtils.getTotalBookingOverview(bookingOverviewData, 'totalBookingSalon'),
            preValue: bookingReportUtils.getTotalBookingOverview(previousBookingOverviewData, 'totalBookingSalon'),
            color: '#FFFAEB',
            icon: <IconSalon />
        },
        {
            label: "Doanh thu đặt lịch",
            value: bookingReportUtils.getTotalBookingOverview(bookingOverviewData, 'totalRevenueBooking'),
            preValue: bookingReportUtils.getTotalBookingOverview(previousBookingOverviewData, 'totalRevenueBooking'),
            color: '#EFF4FF',
            icon: <IconRevenue />,
        }
    ], [bookingOverviewData, previousBookingOverviewData]);

    return (
        <FlexBox className='overview-cards'>
            {bookingOverviews.map((o, i) => (
                <Card key={i} className='card-overview-booking' loading={isLoading}>
                    <FlexBox gap={8} alignItems='center' flex='none'>
                        <Avatar
                            style={{ backgroundColor: o.color }}
                            size={42}
                            icon={o.icon}
                        />
                        <FlexBox direction='column'>
                            <Text color='#8F9BB2' maxLines={1} maxWidth={200} fontWeight={500} >{o.label}</Text>
                            <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                <Block>
                                    <Text color='#0A1F44' fontWeight={500} fontSize={20}>
                                        {o.value.toLocaleString('en-US')}
                                    </Text>
                                </Block>
                                {renderGrow(o.value, o.preValue)}
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Card>
            ))}
        </FlexBox>
    );
}
