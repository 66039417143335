import { useSearchParams } from "@/hooks";

interface PaginationBookingServiceCategory{
    readonly serviceCategorySearch: string;
    readonly serviceCategoryPage: string;
    readonly serviceCategoryPageSize: string;
    readonly serviceCategoryOrderDirection?: string;
    readonly serviceCategoryOrderBy?: string;
}

export function usePaginationBookingServiceCategory() {
    const initPagination: PaginationBookingServiceCategory = {
        serviceCategorySearch: '',
        serviceCategoryPage: '1',
        serviceCategoryPageSize: '20',
        serviceCategoryOrderDirection: '',
        serviceCategoryOrderBy:''
    }

    return useSearchParams<PaginationBookingServiceCategory>(initPagination, [
        'serviceCategoryOrderBy',
        'serviceCategoryOrderDirection',
        'serviceCategoryPage',
        'serviceCategoryPageSize',
        'serviceCategorySearch'
    ]);
}