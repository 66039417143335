import { CustomerOverviewType, GetCustomerOverviewResponse } from "@/api";
import { UseQueryResult } from "@tanstack/react-query";
import { groupBy } from "lodash";

const getCustomerOverviewState = (
	revenueQueriesResult: UseQueryResult<GetCustomerOverviewResponse, Error>[]
) => {
    const value = revenueQueriesResult?.reduce((total, item) => (total += item.data?.value ?? 0), 0) ?? 0;
    const isLoading = revenueQueriesResult?.some(item => item.isFetching === true);

    return {
        value,
        isLoading
    }
};

export const getCustomerOverviewStates = (
	queriesResult: UseQueryResult<GetCustomerOverviewResponse, Error>[]
) => {
	const groupQueriesResult = groupBy(queriesResult, (result) => result.data?.overviewType);

	const revenueQueriesResult = groupQueriesResult[CustomerOverviewType.Revenue];
	const customerNewQueriesResult = groupQueriesResult[CustomerOverviewType.New];
	const customerTotalQueriesResult = groupQueriesResult[CustomerOverviewType.Total];
	const customerVisitQueriesResult = groupQueriesResult[CustomerOverviewType.WalkIn];
	const customerComBackQueriesResult = groupQueriesResult[CustomerOverviewType.Return];

	const revenueTotal = getCustomerOverviewState(revenueQueriesResult);

    const customersNew = getCustomerOverviewState(customerNewQueriesResult);

    const customerVisit = getCustomerOverviewState(customerVisitQueriesResult);

    const customerTotal = getCustomerOverviewState(customerTotalQueriesResult);
    
    const customersComBack = getCustomerOverviewState(customerComBackQueriesResult);

    return {
		revenueTotal,
        customersNew,
		customerVisit,
        customerTotal,
        customersComBack
	};
};