
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';

export interface GetBookingSummaryStatusRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly salonBranchId?: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetBookingSummaryStatusResponse {
    readonly salonId?: number;
    readonly salonBranchId?: number;
    readonly totalBookingNew: number;
    readonly totalBookingConfirm: number;
    readonly totalBookingCheckIn: number;
    readonly totalBookingCheckOut: number;
    readonly totalBookingCancel: number;
    readonly totalBookingNotCome: number;
}

export const getBookingSummaryStatus = httpUtil.createHttpRequestMeta<
GetBookingSummaryStatusRequest,
    GetBookingSummaryStatusResponse
>({
    baseUrl: env.orgServer,
    path: '/bookings/summary-status',
    method: 'GET',
    authentication: 'bearer'
});
