import { TableColumnsType, Tag } from "antd";
import { ColumnActions, TableCustom } from "@components/table";
import { useHttpQuery } from "@hooks/useHttpQuery";
import { deleteAccount, GetAllAccountResponse, getAllAccounts } from "@/api";
import { accountInfoState, globalModalState, salonsState } from "@components/state";
import { useRecoilState, useResetRecoilState } from "recoil";
import { AccountForm } from "@/components/form";
import { useHttpCommand, usePagination } from "@/hooks";
import { useCallback } from "react";

export function AccountTable() {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salons] = useRecoilState(salonsState);
    const [account] = useRecoilState(accountInfoState);

    const [pagination, setPagination] = usePagination();
    const { data: accounts , refetch} = useHttpQuery(getAllAccounts, {
        query: {
            ...pagination
        }
    });

    const resetModalState = useResetRecoilState(globalModalState);

    const {mutateAsync: deleteAccountMutate} = useHttpCommand(deleteAccount, {
        onSuccess() {
            refetch();
            resetModalState();
        }
    });

    const createAccount = () => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: "Thêm tài khoản mới",
            content: <AccountForm onSuccess={()=> refetch()}/>
        });
    };

    const handleDeleteAccount = useCallback((record: GetAllAccountResponse) => {
        setGlobalModal({
            isOpen: true,
            showModalDelete: true,
            title: `Bạn chắc chắn muốn xoá tài khoản “${record.name}”?`,
            onOk: async () => await deleteAccountMutate({pathData: {id: record.id}}),
        });
    }, [deleteAccountMutate, setGlobalModal]);

    const updateAccount = (record: GetAllAccountResponse) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: "chỉnh sửa tài khoản",
            onCancel() {
                resetModalState()
            },
            content: <AccountForm 
                defaultValues={{
                    ...record,
                    salonIds: record.orgAccountSalons?.map(o=> o.salonId),
                }}
                onSuccess={()=> refetch()}
            />
        });
    };

    const renderSalons = (record: GetAllAccountResponse)=> {
        if(record.rootAccount) {
            return salons?.map(o=> <Tag>{o.name}</Tag>);
        }

        return record.orgAccountSalons?.map(o=> <Tag>{salons?.find(s=> s.id === o.salonId)?.name}</Tag>)
    }

    const columns: TableColumnsType<GetAllAccountResponse> = [
        {
            title: 'Tên tài khoản',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Điện thoại',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '300px'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '300px'
        },
        // {
        //     title: 'Vai trò',
        //     dataIndex: 'rootAccount',
        //     key: 'rootAccount',
        //     render: (_, record) => record.rootAccount ? 'Admin': 'Member',
        //     width: '250px'
        // },
        {
            title: 'Salon',
            render:(_, record) => renderSalons(record),
            key: 'salon',
            width: '250px'
        },
        {
            title: "",
            render: (_, record) => {
                if(account?.rootAccount && record.rootAccount) return (
                    <ColumnActions
                        viewDetailUrl=""
                        onClickEdit={() => updateAccount(record)}
                    />
                );

                if(record.rootAccount) return;

                return (
                    <ColumnActions
                        viewDetailUrl=""
                        onClickEdit={() => updateAccount(record)}
                        onClickDelete={() => handleDeleteAccount(record)}
                    />
                )
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            showHeader={true}
            columns={columns}
            onPageChange={(nextPage) => {
                setPagination({
                    ...pagination,
                    page: nextPage.toString()
                });
            }}
            pageInfo={accounts?.meta}
            onCreate={createAccount}
            btnCreateText="Thêm tài khoản"
            dataSource={accounts?.items}
        />
    )
}
